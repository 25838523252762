import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import '../Css/Digital_Marketing.css';
import AOS from 'aos';
import DigitalImg from '../Services pages/images/digitel.jpg';

function DigitalMarketing() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div className="digital-marketing-page">
      {/* Header Section */}
      <div className="digital-marketing-header-section">
        <img src={DigitalImg} alt="Digital Marketing" className="digital-marketing-header-image" />
        <div className="digital-marketing-overlay-text">
          <h1>Next-gen Digital Marketing Services</h1>
          <p>Empower Your Brand With Technology-powered Marketing Expertise</p>
        </div>
      </div>

      {/* Info Section */}
      <div className="digital-marketing-info-section" data-aos="fade-up">
        <h2>
          We’re Your <span className="highlighted-text">Go-to Partner In Digital Success</span>
        </h2>
        <p>
          Not Just An Ordinary Digital Marketing Agency But A Leading Name You Can Trust For All Your Branding, Designing, Marketing, and Advertising Needs.
        </p>
        <p>
          As the most prominent face of digital marketing for thousands of clients worldwide, we pride ourselves in providing ingenious and innovative sales, lead generation, email marketing, graphical content, and digital marketing solutions to small, medium, large, and Fortune 500 Companies.
        </p>
        <p>
          Cloud Analogy, a purpose-driven & result-oriented Sales and digital marketing agency, is uniquely qualified to help businesses get more leads, phone calls, transactions, customer loyalty, and, most importantly, REVENUE!
        </p>
      </div>

      {/* What We Do Section */}
      <div className="digital-marketing-services-section" data-aos="fade-up">
        <h2>What We Do</h2>
        <div className="digital-marketing-services-list">
          <div className="digital-marketing-service-item">
            <h3>A New Age Smart Website Design & Development</h3>
            <p>Crafting websites that speak aloud innovation and functionality.</p>
          </div>
          <div className="digital-marketing-service-item">
            <h3>Graphic Designing</h3>
            <p>Transforming ideas into eye-catching visuals that captivate.</p>
          </div>
          <div className="digital-marketing-service-item">
            <h3>Content Marketing</h3>
            <p>From words to wonders, we craft compelling content that shines.</p>
          </div>
          <div className="digital-marketing-service-item">
            <h3>Lead Generation</h3>
            <p>Unlocking pathways to opportunities, leads, and conversions.</p>
          </div>
          <div className="digital-marketing-service-item">
            <h3>Social Media Optimization</h3>
            <p>Boosting your online presence for more visitors & interactions.</p>
          </div>
          <div className="digital-marketing-service-item">
            <h3>Search Engine Optimization</h3>
            <p>Elevate your digital footprint & conquer search engine dominance.</p>
          </div>
          <div className="digital-marketing-service-item">
            <h3>Email Marketing</h3>
            <p>Sending emails that bring real value and results to your business.</p>
          </div>
          <div className="digital-marketing-service-item">
            <h3>Portfolio Management</h3>
            <p>We handle your success stories with care and precision.</p>
          </div>
        </div>
      </div>

      {/* How Do We Do It Section */}
<div className="digital-marketing-methods-section" data-aos="fade-up">
  <h2>How Do We Do It?</h2>
  <div className="digital-marketing-methods-list">
    <div className="digital-marketing-method-item">
      <div className="method-icon">
        <i className="fas fa-cogs"></i>
      </div>
      <h3>Tailored Strategy</h3>
      <p>Craft personalized plans to suit your unique business goals.</p>
    </div>

    {/* Targeted Audience Block with an Icon */}
    <div className="digital-marketing-method-item">
      <div className="method-icon">
        <i className="fas fa-bullseye"></i> {/* Added icon */}
      </div>
      <h3>Targeted Audience</h3>
      <p>Identify and engage with your ideal customer base.</p>
    </div>

    <div className="digital-marketing-method-item">
      <div className="method-icon">
        <i className="fas fa-pen"></i>
      </div>
      <h3>Creative Content</h3>
      <p>Develop captivating content that resonates with your audience.</p>
    </div>
    <div className="digital-marketing-method-item">
      <div className="method-icon">
        <i className="fas fa-chart-line"></i>
      </div>
      <h3>Data-Driven Decisions</h3>
      <p>Analyze data to optimize performance and drive results.</p>
    </div>
    <div className="digital-marketing-method-item">
      <div className="method-icon">
        <i className="fas fa-network-wired"></i>
      </div>
      <h3>Multi-Channel Presence</h3>
      <p>Leverage various platforms to maximize your online presence.</p>
    </div>
    <div className="digital-marketing-method-item">
      <div className="method-icon">
        <i className="fas fa-sync"></i>
      </div>
      <h3>Continuous Optimization</h3>
      <p>Constantly refine our strategies for ongoing success.</p>
    </div>
  </div>
</div>

    </div>
  );
}

export default DigitalMarketing;
