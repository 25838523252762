import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css'; // Import AOS CSS
import '../Css/Marketing_Cloud.css'; // Ensure this CSS file has updated styles
import HeaderImg from '../Services pages/images/marketingheader.jpg'; // Update with your image path
import AOS from 'aos';

function MarketingCloud() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animation
      easing: 'ease-in-out', // Easing function
      once: true, // Animate only once
    });
  }, []);

  return (
    <div>
        <section className="position-relative">
        <div className="image-wrapper">
          <img src={HeaderImg} alt="Marketing Cloud" />
        </div>
        <div className="overlay-text position-absolute top-50 start-50 translate-middle text-center text-white p-3">
          <h1 className="display-5 fw-bold" data-aos="fade-up">
            Salesforce Marketing Cloud Implementation Services
          </h1>
          <p className="lead mt-3" data-aos="fade-up" data-aos-delay="200">
            Create and Deliver Personalized Customer Experiences
          </p>
          <p className="mt-3" data-aos="fade-up" data-aos-delay="400">
            Salesforce Marketing Cloud Implementation Services from WWApps enable you to drive personalized and data-driven marketing. Deliver personalized consumer engagement with this integrated consumer engagement platform – that scales across all channels: web, mobile, email, social, and digital advertising using the best of Marketing Cloud Salesforce.
          </p>
        </div>
      </section>

      {/* Tools Section */}
      <section className="tools-section py-5">
        <div className="container">
          <div className="text-center mb-5" data-aos="fade-up">
            <h2 className="display-4 fw-bold">Tools for Salesforce Marketing Cloud Implementation Services</h2>
            <p className="lead">
              Identify new customers or nurture the existing customers with the help of Salesforce Marketing Cloud tools to connect with customers across all channels.
            </p>
          </div>
          <div className="row g-4">
            
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
              <div className="card h-100 shadow-sm">
                <div className="card-body text-center">
                  <i className="fas fa-database fa-3x mb-3"></i> {/* Placeholder for Icon */}
                  <h5 className="card-title">Salesforce Data Studio</h5>
                  <p className="card-text">
                    Expand your reach and discover new audiences while maintaining data security with Salesforce Data Studio. Access more targeted audiences securely with the help of Salesforce Marketing Cloud Consultants.
                  </p>
                </div>
              </div>
            </div>

            
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
              <div className="card h-100 shadow-sm">
                <div className="card-body text-center">
                  <i className="fas fa-chart-bar fa-3x mb-3"></i> {/* Placeholder for Icon */}
                  <h5 className="card-title">Salesforce DMP</h5>
                  <p className="card-text">
                    Capture and store consumer data across all touchpoints. Use data-driven strategies to increase engagement and revenue with Salesforce’s powerful Data Management Platform.
                  </p>
                </div>
              </div>
            </div>

            
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
              <div className="card h-100 shadow-sm">
                <div className="card-body text-center">
                  <i className="fas fa-exchange-alt fa-3x mb-3"></i> {/* Placeholder for Icon */}
                  <h5 className="card-title">Interaction Studio</h5>
                  <p className="card-text">
                    Deliver real-time, contextually relevant experiences across all channels. React to customer behavior in real time and offer personalized promotions based on interactions with your brand.
                  </p>
                </div>
              </div>
            </div>

            
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="400">
              <div className="card h-100 shadow-sm">
                <div className="card-body text-center">
                  <i className="fas fa-envelope fa-3x mb-3"></i> {/* Placeholder for Icon */}
                  <h5 className="card-title">Email Studio</h5>
                  <p className="card-text">
                    Send personalized emails and track performance with advanced analytics. Use customizable templates and A/B testing to optimize email campaigns for higher engagement.
                  </p>
                </div>
              </div>
            </div>

            
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="500">
              <div className="card h-100 shadow-sm">
                <div className="card-body text-center">
                  <i className="fas fa-map-signs fa-3x mb-3"></i> {/* Placeholder for Icon */}
                  <h5 className="card-title">Journey Builder</h5>
                  <p className="card-text">
                    Automate customer journeys across emails, mobile, and ads. Adjust customer journeys based on real-time data and behavior to create a seamless customer experience.
                  </p>
                </div>
              </div>
            </div>

            
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="600">
              <div className="card h-100 shadow-sm">
                <div className="card-body text-center">
                  <i className="fas fa-mobile-alt fa-3x mb-3"></i> {/* Placeholder for Icon */}
                  <h5 className="card-title">Mobile Studio</h5>
                  <p className="card-text">
                    Engage customers through personalized push notifications, SMS, and mobile messaging. Send the right message at the right time based on location, proximity, and events.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why WWApps Section */}
      <section className="why-cloud-analogy py-5 bg-light">
        <div className="container">
          <div className="text-center mb-5" data-aos="fade-up">
            <h2 className="display-4 fw-bold">Why WWApps?</h2>
            <p className="lead">
              Drive Individual Customer Journeys, Track with Predictive Analytics
            </p>
          </div>

          
          <div className="mb-5" data-aos="fade-up" data-aos-delay="100">
            <h4 className="fw-bold">Guide Individual Customer Journeys</h4>
            <p>
              Marketers are immensely benefited by listening to the customers – gathering information on what all are done by the targets and what they are likely to do. Create personalized customer interactions. Use the Journey Builder to listen to the customers and set off actions based on triggers and guide individual customer journeys with the help of personalized messages. Deliver more relevant content by the marketers.
            </p>
          </div>

          
          <div className="mb-5" data-aos="fade-up" data-aos-delay="200">
            <h4 className="fw-bold">Track Customer Preferences with Predictive Analytics</h4>
            <p>
              Predictive Analytics is a useful tool to shape the customer experience with Salesforce Marketing Cloud. Assess the past behavioral pattern of the customer with the help of Salesforce Marketing Cloud Consultant and predict future actions. Decide on what the customer is expected to subsequently do, based on their past behavior. Send relevant messages by the marketers on any sale of items, if the customer is in the habit of buying from sales. Evoke the right response from them based on your preferences.
            </p>
          </div>

          
          <div data-aos="fade-up" data-aos-delay="300">
            <h4 className="fw-bold">Delivers Content Based on Customer Health</h4>
            <p>
              Ensure that the marketers send messages to the customers based on their customer satisfaction levels. There is no point in sending renewal requests for subscriptions to an irate customer. Suspend all activities until a complex issue is resolved, and then resume sending messages to enhance customer satisfaction levels. Drive individual customer journeys with the marketer based on this sentiment.
            </p>
          </div>
        </div>
        </section>
    </div>
  );
}

export default MarketingCloud;
