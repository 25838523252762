import React, { useRef, useEffect } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Slider from '../Inc/Slider';
import HomePic from '../Images/about.jpg';
import MissionImage from '../Images/mision.jpg';
import VisionImage from '../Images/vision.jpg';
import CertImage1 from '../Images/Administrator.png'; 
import CertImage2 from '../Images/Marketing-Cloud-Administrator.png';
import CertImage3 from '../Images/MarketingAssociate.png';
import CertImage4 from '../Images/Platform-App-Builder.png';
import CertImage5 from '../Images/service cloud consultant.png';
import CertImage6 from '../Images/architect.png';
import CertImage7 from '../Images/business.png';
import CertImage8 from '../Images/react2.png';
import CertImage9 from '../Images/oracle.png';
import TrustedCompany1 from '../Images/logo1.png';
import TrustedCompany2 from '../Images/logo2.png';
import TrustedCompany3 from '../Images/logo3.png';
import TrustedCompany4 from '../Images/logo4.png';
import TrustedCompany5 from '../Images/logo5.png';
import TrustedCompany6 from '../Images/logo6.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Pages/Css/Home.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {
  
    useEffect(() => {
        AOS.init({ duration: 1000, once: true });
        return () => AOS.refresh();
    }, []);

    
    

    return (
        <div>
            <Slider />

            {/* Home Section */}
            <section className="home-info-section py-5">
                <Container>
                    <Row className="align-items-center">
                        <Col md={6} className="text-center text-md-start" data-aos="fade-up">
                            <h1 className="home-info-title mb-4">Discover Our Journey and Expertise</h1>
                            <p className="home-info-text mb-4">
                                With over a decade of innovation and excellence, we are your trusted partner in IT solutions.
                            </p>
                            <Button className="home-info-btn" variant="primary">Meet the Team</Button>
                        </Col>
                        <Col md={6} className="mt-4 mt-md-0" data-aos="zoom-in">
                            <Image src={HomePic} alt="About Us" className="img-fluid rounded" />
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Mission and Vision Section */}
            <section className="home-mission-vision-section py-5">
                <Container>
                    <Row>
                        <Col md={6} className="mb-4 mb-md-0 d-flex align-items-stretch" data-aos="slide-up">
                            <div className="home-mission-content d-flex flex-column">
                                <h2 className="home-mission-title">Our Mission</h2>
                                <Image src={MissionImage} alt="Mission" className="home-mission-image img-fluid" />
                                <p className="home-mission-text">
                                    Our mission is to deliver innovative IT solutions that drive growth and success for our clients.
                                </p>
                            </div>
                        </Col>
                        <Col md={6} className="d-flex align-items-stretch" data-aos="slide-left">
                            <div className="home-vision-content d-flex flex-column">
                                <h2 className="home-vision-title">Our Vision</h2>
                                <Image src={VisionImage} alt="Vision" className="home-vision-image img-fluid" />
                                <p className="home-vision-text">
                                    Our vision is to be the leading provider of cutting-edge IT solutions.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Certification Section */}
            <section className="home-certification-section py-5" data-aos="fade-up">
                <Container>
                    <h2 className="home-certification-title mb-5">Our Certifications</h2>
                    <div className="home-certification-images">
    <div className="row-1">
        <Image src={CertImage1} alt="Certification 1" className="home-img-fluid" />
        <Image src={CertImage2} alt="Certification 2" className="home-img-fluid" />
        <Image src={CertImage3} alt="Certification 3" className="home-img-fluid" />
        <Image src={CertImage4} alt="Certification 4" className="home-img-fluid" />
    </div>
    <div className="row-2">
        <Image src={CertImage5} alt="Certification 5" className="home-img-fluid" />
        <Image src={CertImage6} alt="Certification 6" className="home-img-fluid" />
        <Image src={CertImage7} alt="Certification 7" className="home-img-fluid" />
    </div>
    <div className="row-3">
        <Image src={CertImage8} alt="Certification 8" className="home-img-fluid" />
        <Image src={CertImage9} alt="Certification 9" className="home-img-fluid" />
    </div>
   
</div>

                </Container>
            </section>

            {/* Trusted By Section */}
<section className="home-trusted-by-section py-5" data-aos="fade-up">
    <Container>
        <h2 className="home-trusted-by-title mb-5 text-center">Trusted By Companies</h2>
        <div className="home-trusted-images">
            <div className="home-trusted-container">
                <Image src={TrustedCompany1} alt="Trusted Company 1" className="home-trusted-img" />
            </div>
            <div className="home-trusted-container">
                <Image src={TrustedCompany2} alt="Trusted Company 2" className="home-trusted-img" />
            </div>
            <div className="home-trusted-container">
                <Image src={TrustedCompany3} alt="Trusted Company 3" className="home-trusted-img" />
            </div>
            <div className="home-trusted-container">
                <Image src={TrustedCompany4} alt="Trusted Company 4" className="home-trusted-img" />
            </div>
            <div className="home-trusted-container">
                <Image src={TrustedCompany5} alt="Trusted Company 5" className="home-trusted-img" />
            </div>
            <div className="home-trusted-container">
                <Image src={TrustedCompany6} alt="Trusted Company 6" className="home-trusted-img" />
            </div>
        </div>
        
    </Container>
</section>

        </div>
    );
}

export default Home;
