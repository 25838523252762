import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import '../Css/Admin_Developer.css';
import AOS from 'aos';
import HeaderImg from '../Services pages/images/AdminDevelop.jpg';
import Img1 from '../Services pages/images/AdminDevelop1.jpg';
import Img2 from '../Services pages/images/AdminDevelop2.jpg';

function AdminDeveloper() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div className="admin-developer-container">
      {/* Header Section */}
      <header className="admin-developer-header">
        <img src={HeaderImg} alt="Admin Developer" className="admin-developer-img img-fluid" />
        <div className="admin-developer-overlay-text">
          <h1 className="admin-developer-title">Salesforce Admin & Developer Support</h1>
          <p className="admin-developer-description">
            Minimize technology investment with proven administrative and customization services of Salesforce technology
            by the professional Salesforce Admin & Developer Support Services from WWApps.
          </p>
        </div>
      </header>

      {/* Support Services Section */}
      <section className="admin-developer-support">
        <div className="admin-developer-row row">
          <div className="admin-developer-col-left col-md-6">
            <img src={Img1} alt="Support Services" className="admin-developer-support-img img-fluid" />
          </div>
          <div className="admin-developer-col-right col-md-6">
            <h2 className="admin-developer-subtitle">Salesforce Admin & Developer Support Services</h2>
            <p className="admin-developer-text">
              In the Salesforce Universe, it is possible to tackle every software support needs of your business. However, 
              the professional expertise of a team of certified Salesforce experts would resolve issues with élan for administration 
              and development, and increasing user adoption. WWApps, over the years, has established itself as one of the 
              prime companies offering outstanding Salesforce Admin & Developer Support services. We have demonstrated exceptional 
              skills in transforming the businesses of our clients by being their extended team. Our comprehensive services include 
              a range of activities including administration and customization of your Salesforce tasks.
            </p>
          </div>
        </div>
      </section>

      {/* What Sets Us Apart Section */}
      <section className="admin-developer-unique">
        <h2 className="admin-developer-unique-title">What Sets Us Apart?</h2>
        <p className="admin-developer-unique-description">
          Put aside all your worries related to Salesforce issues. Our technically qualified Salesforce specialists have hands-on 
          experience working on various projects. Invest in the right talent and minimize risks with outstanding services from 
          WWApps. Our services guarantee you reliability, cost-effectiveness, and superior quality.
        </p>
        <div className="admin-developer-unique-cards">
          <div className="admin-developer-card">
            <img src={Img2} alt="Qualified Professionals" className="admin-developer-card-img" />
            <h3 className="admin-developer-card-title">Qualified Professionals</h3>
            <p className="admin-developer-card-text">
              Our talented pool of qualified professionals with their vast industry experience help you in increasing user adoption and maximize the returns for your investment in technology.
            </p>
          </div>
          <div className="admin-developer-card">
            <img src={Img2} alt="Hand-picked Team" className="admin-developer-card-img" />
            <h3 className="admin-developer-card-title">Hand-picked Team</h3>
            <p className="admin-developer-card-text">
              We handpick the developers with the right set of analytical skills to suit your support needs – relieving you of the pain to set up an in-house team to monitor Salesforce.
            </p>
          </div>
          <div className="admin-developer-card">
            <img src={Img2} alt="Flexible Support Packages" className="admin-developer-card-img" />
            <h3 className="admin-developer-card-title">Flexible Support Packages</h3>
            <p className="admin-developer-card-text">
              We offer the flexibility to choose a suitable engagement model that suits diverse client requirements, while you continue to experience impeccable services from us.
            </p>
          </div>
          <div className="admin-developer-card">
            <img src={Img2} alt="Faster Response Time" className="admin-developer-card-img" />
            <h3 className="admin-developer-card-title">Faster Response Time</h3>
            <p className="admin-developer-card-text">
              We provide on-time services to your Salesforce challenges before they burgeon into a critical issue – jeopardizing business operations with faster response time and speedy resolutions.
            </p>
          </div>
        </div>
      </section>

      {/* Additional Benefits Section */}
      <section className="admin-developer-benefits">
        <h2 className="admin-developer-benefits-title">Additional Benefits</h2>
        <div className="admin-developer-benefits-content">
          <div className="admin-developer-benefit-item">
            <h3 className="admin-developer-benefit-title">Accelerate Business Growth</h3>
            <p className="admin-developer-benefit-text">
              At WWApps, our team of certified Salesforce professionals has established proven capabilities to mitigate any risk or challenge that may arise with your Salesforce instance. Our Salesforce Admin and Developer Support services extend cloud computing capabilities with reduced delivery costs.
            </p>
          </div>
          <div className="admin-developer-benefit-item">
            <h3 className="admin-developer-benefit-title">Focus on your Core Functions</h3>
            <p className="admin-developer-benefit-text">
              We proactively monitor Salesforce implementation and identify areas of performance improvements; developing best practices, to help you focus on your core business functions.
            </p>
          </div>
          <div className="admin-developer-benefit-item">
            <h3 className="admin-developer-benefit-title">Dedicated Resources</h3>
            <p className="admin-developer-benefit-text">
              Finding the right resource with niche skills is always difficult. We specialize in providing dedicated resources according to your project requirements and its complexities.
            </p>
          </div>
          <div className="admin-developer-benefit-item">
            <h3 className="admin-developer-benefit-title">Scalable Resources</h3>
            <p className="admin-developer-benefit-text">
              Based on your project needs, we offer you the flexibility to scale up or scale down the Salesforce support resources to meet your developer support needs for higher end-user adoption.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdminDeveloper;
