import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import '../Css/Community_Sites.css'; // Add responsive styles here
import AOS from 'aos';
import CommunityImg from '../Services pages/images/communitysites.jpg';

function CommunitySites() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div className="community-sites">
      {/* Header Section */}
      <header className="community-sites-header position-relative text-white">
        <img
          src={CommunityImg}
          alt="Salesforce Communities"
          className="community-sites-img img-fluid w-100"
        />
        <div
          className="community-sites-overlay-container d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100"
          data-aos="fade-in"
        >
          <div className="community-sites-overlay bg-dark opacity-75 p-4 rounded text-center">
            <h1 className="community-sites-title display-4" data-aos="zoom-in">Salesforce Communities and Sites</h1>
            <p className="community-sites-description lead" data-aos="zoom-in">
              Connect and collaborate with your customers, employees, and partners by delivering
              rich experiences with Salesforce Communities Development services from Cloud Analogy.
            </p>
          </div>
        </div>
      </header>

      {/* New Section */}
      <section className="community-sites-content py-5 text-center">
        <div className="container">
          <h2 className="community-sites-subtitle mb-4" data-aos="fade-up">
            Rich Experiences through Salesforce Communities Development
          </h2>
          <p className="community-sites-text lead mb-4" data-aos="fade-up">
            Customize your communities conveniently and intuitively. We help you connect with your
            customers, employees, and partners at a larger scale through Salesforce Communities.
            With Salesforce Community Builder, we design pixel-perfect branded pages to help you
            build personalized platforms for varied purposes – portal, help forum, customer experiences,
            or an online community.
          </p>
          <p className="community-sites-text lead mb-4" data-aos="fade-up">
            Our specialized services in Salesforce Communities development will help you build branded
            portals with personalized content, providing proactive customer service and superior
            experiences. We also help you enable purposeful interactions between your employees and
            partners to accelerate channel sales through your partners. Hire Salesforce Communities
            Development specialists now!
          </p>
        </div>
      </section>

      {/* Why WWApps Section */}
      <section className="community-sites-why-wwapps py-5">
        <div className="container">
          <h2 className="community-sites-why-title text-center mb-5" data-aos="fade-up">
            Why WWApps?
          </h2>
          <p className="community-sites-why-text lead text-center mb-5" data-aos="fade-up">
            We are the leading Salesforce Communities Development Company steadfastly growing with our
            innovative customer-centric cloud-based solutions. Our out-of-the-box approach to business problems has put us on
            the global map as the most reliable Salesforce Partner. Our versatile team of certified Salesforce professionals
            and technology experts set our services apart with their expertise in all the flagship Salesforce products.
          </p>

          <div className="row text-center">
            {/* First Feature */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="community-sites-icon-box mb-3">
                <i className="fas fa-cloud"></i> {/* Example icon for Easy Access */}
              </div>
              <h4 className="community-sites-feature-title">Easy Access</h4>
              <p className="community-sites-feature-text">
                Robust platform selection can empower your organization's growth. We help you select a platform
                that addresses your future requirements – reducing operational costs.
              </p>
            </div>

            {/* Second Feature */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="community-sites-icon-box mb-3">
                <i className="fas fa-chart-bar"></i> {/* Example icon for Better Interaction */}
              </div>
              <h4 className="community-sites-feature-title">Analytics to Monitor</h4>
              <p className="community-sites-feature-text">
                Track user behavior and monitor your community's performance with real-time reports and dashboards.
                Get detailed insights to stay ahead of the competition.
              </p>
            </div>

            {/* Third Feature */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="community-sites-icon-box mb-3">
                <i className="fas fa-cogs"></i> {/* Example icon for Customization */}
              </div>
              <h4 className="community-sites-feature-title">Leverage Customization</h4>
              <p className="community-sites-feature-text">
                Extensive customization of apps will help you maximize Salesforce solutions.
                Engage users and achieve your business goals through tailored customization.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CommunitySites;
