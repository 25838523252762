import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import '../Css/Manufacturing_Cloud.css';
import AOS from 'aos';
import ManufacturingImg from '../Services pages/images/manufacturing1.jpg';
import ManufacturingImg1 from '../Services pages/images/manufacturingg.jpg';

function ManufacturingCloud() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div>
      {/* Header Section */}
      <div className="manufacturing-cloud-header" data-aos="fade-in" data-aos-duration="1500">
        <div className="manufacturing-cloud-overlay">
          <h1 data-aos="zoom-in" data-aos-duration="1500">Salesforce Manufacturing Cloud</h1>
          <p data-aos="fade-up" data-aos-duration="1500">One platform. A 360-degree and complete view of your business.</p>
        </div>
      </div>

      {/* Main Content Section */}
      <section className="manufacturing-cloud-content">
        <div className="heading-container" data-aos="fade-up" data-aos-duration="1500">
          <h2>Leading CRM Solutions for the Manufacturing Sector</h2>
          <p data-aos="fade-up" data-aos-delay="100">
            Discover the top CRM Cloud Solutions for Manufacturing Companies with Salesforce Manufacturing Cloud. WWApps is here to guide you through digital transformation and accelerate your business growth.
          </p>
        </div>
        <div className="manufacturing-cloud-content-item" data-aos="flip-left" data-aos-duration="1500">
          <h3 className="subheading">Manage the Life Cycle of Sales Agreements</h3>
          <p className="section-paragraph">
            Efficiently manage the life cycle of sales agreements in a shared interface through sales agreements powered by the Salesforce Manufacturing Cloud.
          </p>
        </div>
        <div className="manufacturing-cloud-content-item" data-aos="flip-right" data-aos-duration="1500" data-aos-delay="100">
          <h3 className="subheading">ERP Data Integration through Mulesoft</h3>
          <p className="section-paragraph">
            Easily track the performance of accounts via Mulesoft as against an environment of disparate systems by accessing the best CRM Cloud Solutions for Manufacturing Companies.
          </p>
        </div>
        <div className="manufacturing-cloud-content-item" data-aos="fade-left" data-aos-duration="1500" data-aos-delay="200">
          <h3 className="subheading">Powerful Account-Based Forecasting</h3>
          <p className="section-paragraph">
            Allow your teams to develop and leverage more accurate forecasts via a central source of truth. Leverage the Salesforce Manufacturing Cloud like a complete professional.
          </p>
        </div>
        <div className="manufacturing-cloud-content-item" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="300">
          <h3 className="subheading">Field Service Lightning</h3>
          <p className="section-paragraph">
            Harness the potential of Field Service Lightning to resolve product issues faster. Get benefit from triggered alerts within Salesforce about actual or potential field equipment problems.
          </p>
        </div>
      </section>

      {/* Existing Info Section */}
      <section className="manufacturing-cloud-info-section">
        <div className="manufacturing-cloud-info-content" data-aos="fade-up" data-aos-duration="1500">
          <h2>Worldwide Leader in the Salesforce Manufacturing Cloud</h2>
          <p>
            WWApps shapes seamless Manufacturing Industry technologies for increased revenues, streamlined business processes, and connected experiences with Salesforce Communities and B2B Commerce.
          </p>
          <p>
            As the top Salesforce Implementation Partner for Manufacturing companies, WWApps has the responsibility toward the manufacturing industry by helping them leverage new products, solutions, and capabilities.
          </p>
        </div>
        <img src={ManufacturingImg} alt="Manufacturing Cloud" data-aos="zoom-in" data-aos-duration="1500" />
      </section>

      {/* New Info Section */}
      <section className="why-choose-section">
        <div className="why-choose-content" data-aos="fade-up" data-aos-duration="1500">
          <h2>Why Choose WWApps?</h2>
          <p>
            WWApps is the world’s preferred partner and the top Salesforce Implementation Partner for Manufacturing Industry. It has assisted small, medium, and big-sized businesses across the globe implement and integrate out-of-the-box Salesforce Marketing Cloud solutions. This helps our clients transform their legacy systems into intelligent warranty systems.
          </p>
          <p>
            Our teams of certified Salesforce Manufacturing Cloud specialists empower your manufacturing business to access actionable insights and engage seamlessly with customers post-sale.
          </p>
          <p>
            Our clients choose us because of our unique abilities to significantly and consistently maximize their ROI by keeping a close check on their reserves, warranty costs, and cash flow.
          </p>
        </div>
        <img src={ManufacturingImg1} alt="Manufacturing Cloud" data-aos="zoom-in" data-aos-duration="1500" />
      </section>
    </div>
  );
}

export default ManufacturingCloud;
