import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import '../Css/Angular.css'; // Make sure to update the path if needed
import AOS from 'aos';
import AngularImg from '../Services pages/images/angular.jpg'; // Replace with the correct image path
import CustomWebsiteImg from '../Services pages/images/angular1.png'; // Replace with the correct path
import WebPortalImg from '../Services pages/images/angular2.png'; // Replace with the correct path
import EcommerceImg from '../Services pages/images/angular3.png'; // Replace with the correct path
import MobileAppImg from '../Services pages/images/angular4.png'; // Replace with the correct path
import PluginDevImg from '../Services pages/images/angular5.png'; // Replace with the correct path
import UIUXImg from '../Services pages/images/angular6.png'; // Replace with the correct path

function Angular() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div className="angular-page">
      {/* Header Section */}
      <header className="angular-header position-relative text-white" data-aos="fade-up">
        <img
          src={AngularImg} // Replace with your image URL
          alt="AngularJS Development"
          className="angular-img img-fluid w-100"
        />
        <div className="angular-overlay-container d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100" data-aos="fade-in">
          <div className="angular-overlay bg-dark opacity-75 p-4 rounded text-center">
            <h1 className="angular-title display-4" data-aos="zoom-in">AngularJS Development Services</h1>
            <p className="angular-description lead" data-aos="zoom-in">
              Get robust, scalable web and mobile applications developed with remarkable customization by the experienced team of AngularJS developers from WWApps. 
              We are a leading web applications development company with hands-on experience in AngularJS development services providing cross-platform, secure and feature-rich solutions for your complex business requirements.
            </p>
          </div>
        </div>
      </header>

      {/* Angular JS Development Services Section */}
      <section className="angular-services-section py-5">
        <div className="container">
          <h2 className="angular-services-heading text-center mb-5" data-aos="fade-up">Angular JS Development Services</h2>
          <p className="angular-services-lead lead text-center mb-4" data-aos="fade-up">
            Our full-stack Angular JS Development services will drive value to your business with outstanding web and mobile applications. 
            WWApps has a dedicated team of qualified Angular JS developers to craft single-page, cross-platform applications with dynamic and user-friendly front-end.
            We aim to simplify development and testing of applications with model–view–controller (MVC) architecture.
          </p>

          <div className="row text-center">
            {/* Custom Website */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="angular-services-card p-4 border rounded h-100">
                <img src={CustomWebsiteImg} alt="Custom Website" className="img-fluid mb-3" />
                <h4>Custom Website</h4>
                <p>
                  Our established competencies in Angular JS enable us to design and develop customized, business-centric web apps with increased user engagement.
                </p>
              </div>
            </div>

            {/* Web Portal Development */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="angular-services-card p-4 border rounded h-100">
                <img src={WebPortalImg} alt="Web Portal Development" className="img-fluid mb-3" />
                <h4>Web Portal Development</h4>
                <p>
                  We develop interactive web portals using AngularJS technology with customizable floating panels to accomplish attractive and functional portals.
                </p>
              </div>
            </div>

            {/* Feature-rich E-commerce site */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="angular-services-card p-4 border rounded h-100">
                <img src={EcommerceImg} alt="Feature-rich E-commerce site" className="img-fluid mb-3" />
                <h4>Feature-rich E-commerce site</h4>
                <p>
                  We develop user-friendly and feature-rich e-commerce sites that provide seamless experiences across multiple devices with AngularJS.
                </p>
              </div>
            </div>

            {/* Mobile Application Development */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="angular-services-card p-4 border rounded h-100">
                <img src={MobileAppImg} alt="Mobile Application Development" className="img-fluid mb-3" />
                <h4>Mobile Application Development</h4>
                <p>
                  Multi-functional and feature-rich mobile application development is one of our core AngularJS development services.
                </p>
              </div>
            </div>

            {/* Interactive Plug-in Development */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="angular-services-card p-4 border rounded h-100">
                <img src={PluginDevImg} alt="Interactive Plug-in Development" className="img-fluid mb-3" />
                <h4>Interactive Plug-in Development</h4>
                <p>
                  We develop effective plug-ins using the AngularJS framework, tailored to various business needs.
                </p>
              </div>
            </div>

            {/* User-friendly UI/ UX Development */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="angular-services-card p-4 border rounded h-100">
                <img src={UIUXImg} alt="User-friendly UI/UX Development" className="img-fluid mb-3" />
                <h4>User-friendly UI/ UX Development</h4>
                <p>
                  We create seamless and user-friendly UI/UX solutions with appealing aesthetics for cross-platform applications.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="angular-growth-section py-5">
        <div className="container">
          <h2 className="angular-growth-heading text-center mb-5" data-aos="fade-up">
            WWApps – With You in Your Growth
          </h2>
          <p className="angular-growth-intro lead text-center mb-4" data-aos="fade-up">
            We are one of the trusted web development companies with quality services being one of the many attributes that set us apart. We fully use the notable features of AngularJS such as Two-Way Data Binding and regular DOM to build real-time applications. Besides our impeccable project execution, we have successfully delivered business value to our customers across the globe.
          </p>

          <div className="row text-center">
            {/* Full-stack Solutions */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="angular-growth-card p-4 border rounded h-100">
                <div className="angular-growth-icon mb-3">
                  <i className="fas fa-cogs fa-2x"></i> {/* Example icon */}
                </div>
                <h4 className="angular-growth-title">Full-stack Solutions</h4>
                <p>
                  We provide full-stack AngularJS solutions that are most suited for dynamic web applications, offering features of flexibility and scalability.
                </p>
              </div>
            </div>

            {/* Easy Integration */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="angular-growth-card p-4 border rounded h-100">
                <div className="angular-growth-icon mb-3">
                  <i className="fas fa-plug fa-2x"></i> {/* Example icon */}
                </div>
                <h4 className="angular-growth-title">Easy Integration</h4>
                <p>
                  We enable easy and secure integration with third-party frameworks and tools to leverage AngularJS applications for developers.
                </p>
              </div>
            </div>

            {/* Fully-tested Products */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              <div className="angular-growth-card p-4 border rounded h-100">
                <div className="angular-growth-icon mb-3">
                  <i className="fas fa-check-circle fa-2x"></i> {/* Example icon */}
                </div>
                <h4 className="angular-growth-title">Fully-tested Products</h4>
                <p>
                  We ensure that the Angular applications we develop are fully tested for any errors, providing you with bug-free final products.
                </p>
              </div>
            </div>
          </div>

          <p className="angular-growth-summary text-center mt-5" data-aos="fade-up">
            We create amazing web applications based on AngularJS and deliver quality services by providing end-to-end solutions to our clients.
          </p>
        </div>
      </section>

      <section className="angular-advantages-section py-5">
        <div className="container">
          <h2 className="angular-advantages-heading text-center mb-5" data-aos="fade-up">
            Advantages of AngularJS Development
          </h2>

          <div className="row text-center">
            {/* Two-way Data Binding */}
            <div className="col-md-3 mb-4" data-aos="fade-up">
              <div className="angular-advantages-card p-4 border rounded h-100">
                <div className="angular-advantages-icon mb-3">
                  <i className="fas fa-sync-alt fa-2x"></i> {/* Two-way Data Binding Icon */}
                </div>
                <h4 className="angular-advantages-title">Two-way Data Binding</h4>
                <p>
                  It offers synchronization between the model and view, avoiding the need to actively manipulate the DOM, enhancing the performance of your website.
                  It makes the front-end of the application layer effortless with a cleaner UI.
                </p>
              </div>
            </div>

            {/* MVC Components */}
            <div className="col-md-3 mb-4" data-aos="fade-up">
              <div className="angular-advantages-card p-4 border rounded h-100">
                <div className="angular-advantages-icon mb-3">
                  <i className="fas fa-project-diagram fa-2x"></i> {/* MVC Components Icon */}
                </div>
                <h4 className="angular-advantages-title">MVC Components</h4>
                <p>
                  Unlike other frameworks, Angular manages MVC components impeccably. It splits the app into MVC components and connects them efficiently.
                </p>
              </div>
            </div>

            {/* Dependency Injection */}
            <div className="col-md-3 mb-4" data-aos="fade-up">
              <div className="angular-advantages-card p-4 border rounded h-100">
                <div className="angular-advantages-icon mb-3">
                  <i className="fas fa-rocket fa-2x"></i> {/* Dependency Injection Icon */}
                </div>
                <h4 className="angular-advantages-title">Dependency Injection</h4>
                <p>
                  AngularJS utilizes dependency injection to bring view-dependent controllers on the server-side services to client-side applications, reducing server-side burden.
                </p>
              </div>
            </div>

            {/* Routing */}
            <div className="col-md-3 mb-4" data-aos="fade-up">
              <div className="angular-advantages-card p-4 border rounded h-100">
                <div className="angular-advantages-icon mb-3">
                  <i className="fas fa-route fa-2x"></i> {/* Routing Icon */}
                </div>
                <h4 className="angular-advantages-title">Routing</h4>
                <p>
                  Routing is essential for single-page applications. AngularJS makes it easy and efficient to load the appropriate view without directing users to a new page.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Angular;
