import React from 'react';
import { FaLinkedinIn, FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'; // Import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpwork } from '@fortawesome/free-brands-svg-icons';
import { IconButton} from "@mui/material";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../Pages/Css/Footer.css'; // Import custom CSS

function Footer() {
  return (
    <footer className="footer">
      <div className="container footer-container">
        {/* Footer Top */}
        <div className="footer-top">
          {/* Footer About Section */}
          <div className="footer-logo-container">
            <h1 className="footer-logo">WWApps.in</h1>
            <p>
              We are committed to providing the best IT solutions with over a decade of experience. Our team is dedicated to your success.
            </p>
          </div>
          
          {/* Footer Contact Information */}
          <div className="footer-contact-container">
            <h4>Contact Us</h4>
            <ul>
              <li><FaMapMarkerAlt className="footer-icon" /> #1163, Sector 46, Gurugram, Haryana 122002</li>
              <li><FaEnvelope className="footer-icon" /> info@wwapps.in</li>
              <li><FaPhoneAlt className="footer-icon" /> +91-9416853244</li>
            </ul>
          </div>
        </div>
        
        {/* Social Media Links */}
        <div className="d-flex justify-content-center mt-3">
          <IconButton
            component="a"
            href="https://www.upwork.com/freelancers/~01e55640131d143dbc"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Upwork Vinod"
            className="social-icon upwork-1"
          >
            <FontAwesomeIcon icon={faUpwork} className="social-icon upwork" />
          </IconButton>
          <IconButton
            component="a"
            href="https://mail.google.com/mail/?view=cm&fs=1&to=info@wwapps.in"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Email"
          >
            <FaEnvelope className="social-icon email" />
          </IconButton>
          <IconButton
            component="a"
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <FaLinkedinIn className="social-icon linkedin" />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.upwork.com/freelancers/~0171c7d967bc839f14"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Upwork Ritika"
            className="social-icon upwork-2"
          >
            <FontAwesomeIcon icon={faUpwork} className="social-icon upwork-alt" />
          </IconButton>
        </div>
      </div>
      
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} WWApps.in All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
