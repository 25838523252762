import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaCloud, FaChartLine, FaBullhorn, FaHeadset, FaUserFriends, FaChartBar, FaIndustry } from 'react-icons/fa';
import '../Pages/Css/Services.css';

function Services() {
    return (
        <Container fluid className="services-container">
            {/* Hero Section */}
            <section id="services-hero" className="services-hero-section py-5">
                <div><h1>Services</h1></div>
            </section>

            {/* Services Section */}
            <section id="services" className="services-section py-5 text-center animate">
                <h2 className="display-4 font-weight-bold text-primary mb-4">Our Expertise</h2>
                <p className="lead text-muted mb-5">
                    Unlock the full potential of Salesforce with our specialized cloud services.
                </p>
                <Container>
                    <Row className="g-4">
                        {/* Service Item 1 */}
                        <Col lg={4} md={6}>
                            <Card className="service-card text-center border-0 shadow-lg">
                                <Card.Body>
                                    <FaCloud className="service-icon mb-4" />
                                    <Card.Title className="h4 mb-3">Custom App Cloud Development</Card.Title>
                                    <Card.Text className="text-muted">
                                        Tailored cloud applications designed to meet your specific business needs.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Service Item 2 */}
                        <Col lg={4} md={6}>
                            <Card className="service-card text-center border-0 shadow-lg">
                                <Card.Body>
                                    <FaChartLine className="service-icon mb-4" />
                                    <Card.Title className="h4 mb-3">Sales Cloud</Card.Title>
                                    <Card.Text className="text-muted">
                                        Enhance your sales processes with our comprehensive cloud solutions.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Service Item 3 */}
                        <Col lg={4} md={6}>
                            <Card className="service-card text-center border-0 shadow-lg">
                                <Card.Body>
                                    <FaBullhorn className="service-icon mb-4" />
                                    <Card.Title className="h4 mb-3">Marketing Cloud</Card.Title>
                                    <Card.Text className="text-muted">
                                        Drive successful marketing campaigns with our advanced cloud tools.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Service Item 4 */}
                        <Col lg={4} md={6}>
                            <Card className="service-card text-center border-0 shadow-lg">
                                <Card.Body>
                                    <FaHeadset className="service-icon mb-4" />
                                    <Card.Title className="h4 mb-3">Service Cloud</Card.Title>
                                    <Card.Text className="text-muted">
                                        Streamline your customer service operations with our cloud-based solutions.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Service Item 5 */}
                        <Col lg={4} md={6}>
                            <Card className="service-card text-center border-0 shadow-lg">
                                <Card.Body>
                                    <FaUserFriends className="service-icon mb-4" />
                                    <Card.Title className="h4 mb-3">Experience Cloud</Card.Title>
                                    <Card.Text className="text-muted">
                                        Enhance customer experiences with our innovative cloud solutions.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Service Item 6 */}
                        <Col lg={4} md={6}>
                            <Card className="service-card text-center border-0 shadow-lg">
                                <Card.Body>
                                    <FaChartBar className="service-icon mb-4" />
                                    <Card.Title className="h4 mb-3">Analytics Cloud</Card.Title>
                                    <Card.Text className="text-muted">
                                        Gain actionable insights with our powerful analytics cloud services.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Service Item 7 */}
                        <Col lg={4} md={6}>
                            <Card className="service-card text-center border-0 shadow-lg">
                                <Card.Body>
                                    <FaIndustry className="service-icon mb-4" />
                                    <Card.Title className="h4 mb-3">Manufacturing Cloud</Card.Title>
                                    <Card.Text className="text-muted">
                                        Optimize your manufacturing processes with our specialized cloud solutions.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Container>
    );
}

export default Services;
