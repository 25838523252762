import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css'; // Import AOS CSS
import '../Css/Node.css'; // Ensure this CSS file has updated styles
import HeaderImg from '../Services pages/images/node.jpg'; // Update with your image path
import ScalableImg from '../Services pages/images/node1.png'; // Replace with your image path
import RealTimeImg from '../Services pages/images/node2.png'; // Replace with your image path
import ClientSideImg from '../Services pages/images/node3.png'; 
import CustomWebDevImg from '../Services pages/images/node4.png'; // Update with correct path
import ApiDevImg from '../Services pages/images/nodeAPI.png'; // Update with correct path
import UiUxDesignImg from '../Services pages/images/nodeUIUX.png'; // Update with correct path
import CustomPluginImg from '../Services pages/images/nodeplugin.png'; // Update with correct path
import MobileAppImg from '../Services pages/images/nodemobile.png'; // Update with correct path
import EcommerceImg from '../Services pages/images/nodeECom.png'; // Update with correct path
import SingleImg from '../Services pages/images/node10laptop.png'; // Update with the correct path
import AOS from 'aos';

function Node() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animation
      easing: 'ease-in-out', // Easing function
      once: true, // Animate only once
    });
  }, []);

  return (
    <div>
      {/* Header Section */}
      <header className="node-header-section" data-aos="fade-in">
        <div className="node-header-image-container">
          <img src={HeaderImg} alt="Node.js Development" className="img-fluid node-header-image" />
          <div className="node-text-overlay">
            <div className="node-overlay-text">
              <h1>Node.js Development Services</h1>
              <p>
                Build high quality and scalable websites and business applications developed
                on the finest of JavaScript development frameworks – NodeJS. Our experience
                in the top JavaScript (JS) frameworks including NodeJS development will help
                create seamless user experiences, besides maximizing ROI for clients with
                reduced time-to-market.
              </p>
            </div>
          </div>
        </div>
      </header>

      {/* New Section for NodeJS Development Details */}
      <section className="node-details-section" data-aos="fade-up">
        <div className="container">
          <h2><span style={{ color: '#007bff', fontWeight: 'bold' }}>NodeJS</span> Development</h2>
          <p>
            We are one of the leading Web and Mobile Application Development companies specializing in the popular NodeJS framework. Our NodeJS developers are proficient in delivering highly scalable, fast, and real-time applications. As an open-source, cross-platform JavaScript run-time environment, NodeJS can unify web application development based on a single programming language, unlike the conventional different language approach for server-side and client-side scripts. We enable you to build applications with greater user experience utilizing the fastest developmental framework – NodeJS. Having worked with clients in different time zones such as the USA, UK, Australia, etc., our solutions meet the highest industry standards as per the client expectations.
          </p>
          <div className="node-features">
            <div className="node-feature" data-aos="fade-left">
              <img src={ScalableImg} alt="Scalable and Fast Applications" className="node-feature-img" />
              <h3>Scalable and Fast Applications</h3>
              <p>
                NodeJS framework utilizes Google’s V8 engine and can speedily execute JavaScript – helping you build faster and scalable enterprise-level web applications.
              </p>
            </div>
            <div className="node-feature" data-aos="fade-left" data-aos-delay="200">
              <img src={RealTimeImg} alt="Real-time Application Development" className="node-feature-img" />
              <h3>Real-time Application Development</h3>
              <p>
                We develop real-time applications such as gaming and chat using NodeJS development. Our multi-user applications are effortlessly easy and efficient for enterprise deployment.
              </p>
            </div>
            <div className="node-feature" data-aos="fade-left" data-aos-delay="400">
              <img src={ClientSideImg} alt="Rich Client-Side Framework" className="node-feature-img" />
              <h3>Rich Client-Side Framework</h3>
              <p>
                NodeJS helps the developers to quickly implement client-side frameworks on the server-side APIs without the need for translating every time - the JavaScript for the server-side.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* NodeJS Development Services */}
      <section className="node-development-services-section" data-aos="fade-up">
        <div className="container">
          <h2><span style={{ color: '#007bff', fontWeight: 'bold' }}>NodeJS Development Services</span> – the Success Way</h2>
          <div className="node-services">
            <div className="node-service" data-aos="fade-right">
              <div className="node-service-text">
                <h3>Custom Web Development</h3>
                <p>
                  We offer a full technology stack with a wide range of application development services including custom web development with a user-friendly interface as per client requirements.
                </p>
              </div>
              <img src={CustomWebDevImg} alt="Custom Web Development" className="node-service-img" />
            </div>
            <div className="node-service" data-aos="fade-right" data-aos-delay="200">
              <div className="node-service-text">
                <h3>API Development</h3>
                <p>
                  Our skilled NodeJS developers will help you create great APIs to enable information exchange and communication.
                </p>
              </div>
              <img src={ApiDevImg} alt="API Development" className="node-service-img" />
            </div>
            <div className="node-service" data-aos="fade-right" data-aos-delay="400">
              <div className="node-service-text">
                <h3>UI/UX Designing</h3>
                <p>
                  Our expertise in developing unique yet remarkable UI/UX designs results in impressive, flawless, and intuitive designs.
                </p>
              </div>
              <img src={UiUxDesignImg} alt="UI/UX Designing" className="node-service-img" />
            </div>
            <div className="node-service" data-aos="fade-right" data-aos-delay="600">
              <div className="node-service-text">
                <h3>Custom Plug-in</h3>
                <p>
                  We develop highly functional custom plug-ins based on the NodeJS framework. Our NodeJS experts ensure our solutions meet your business requirements.
                </p>
              </div>
              <img src={CustomPluginImg} alt="Custom Plug-in" className="node-service-img" />
            </div>
            <div className="node-service" data-aos="fade-right" data-aos-delay="800">
              <div className="node-service-text">
                <h3>Mobile App</h3>
                <p>
                  Our rich experience in a breadth of executable application development projects helps us build custom mobile apps with superior performance and speed.
                </p>
              </div>
              <img src={MobileAppImg} alt="Mobile App" className="node-service-img" />
            </div>
            <div className="node-service" data-aos="fade-right" data-aos-delay="1000">
              <div className="node-service-text">
                <h3>E-Commerce Web Development</h3>
                <p>
                  We develop a secure online platform for your business to facilitate easy navigation and hassle-free multiple payment gateway options for your online customers.
                </p>
              </div>
              <img src={EcommerceImg} alt="E-Commerce Web Development" className="node-service-img" />
            </div>
          </div>
        </div>
      </section>

      {/* Why WWApps */}
      <section className="node-why-wwapps-section" data-aos="fade-up">
        <div className="node-container">
          <h2 className="node-why-title">Why WWApps</h2>
          <div className="node-accordion-container">
            <img src={SingleImg} alt="Why WWApps" className="node-accordion-image" />
            <div className="node-accordion">
              <details className="node-accordion-item" data-aos="fade-left">
                <summary className="node-accordion-header">Experienced Developers</summary>
                <p>
                  Our skilled and experienced team of NodeJS developers possess the right analytical skills with experience in DBs like Mongo, Cassandra, Postgres, etc.
                </p>
              </details>
              <details className="node-accordion-item" data-aos="fade-left" data-aos-delay="200">
                <summary className="node-accordion-header">Reduced Time-to-Market</summary>
                <p>
                  Our top-notch NodeJS developer team enables you to realize higher benefits and maximize your return on investment (ROI) by a significant reduction in time-to-market.
                </p>
              </details>
              <details className="node-accordion-item" data-aos="fade-left" data-aos-delay="400">
                <summary className="node-accordion-header">End-to-End Support</summary>
                <p>
                  We offer extensive support from requirement gathering to deployment and post-deployment support, ensuring your application is running smoothly and efficiently.
                </p>
              </details>
              <details className="node-accordion-item" data-aos="fade-left" data-aos-delay="600">
                <summary className="node-accordion-header">Cost-Effective Solutions</summary>
                <p>
                  Our competitive pricing and commitment to delivering high-quality solutions ensure that you get the best value for your investment.
                </p>
              </details>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Node;
