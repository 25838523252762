import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css'; // Import AOS CSS
import '../Css/Our_Team.css'; // Ensure this CSS file has updated styles
import HeaderImg from '../Company pages/images/team.png'; // Replace with an appropriate header image
import AOS from 'aos';

function OurTeam() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animation
      easing: 'ease-in-out', // Easing function
      once: true, // Animate only once
    });
  }, []);

  return (
    <div className="ourteam-container">
      <div className="ourteam-header-container">
        <img
          src={HeaderImg}
          alt="Our Team"
          className="ourteam-header-image"
          data-aos="zoom-in"
        />
      </div>

      <div className="ourteam-main-heading-container">
        <h1 className="ourteam-main-heading-title" data-aos="fade-up">
          Meet Our Amazing Team
        </h1>
      </div>

      <div className="ourteam-intro-container">
        <div className="ourteam-introduction-section" data-aos="fade-up" data-aos-delay="100">
          <h2 className="ourteam-subheading">Our Team Introduction</h2>
          <p className="ourteam-introduction">
            Our team is a diverse group of talented professionals dedicated to delivering excellence. Each member brings unique skills and perspectives, working together to achieve exceptional results.
          </p>
        </div>
        <div className="ourteam-mission-section" data-aos="fade-up" data-aos-delay="200">
          <h2 className="ourteam-subheading">Our Mission</h2>
          <p className="ourteam-mission">
            Our mission is to innovate, collaborate, and exceed expectations. We are committed to continuous improvement and fostering a culture of growth and success.
          </p>
        </div>
      </div>

      <div className="ourteam-thankyou-container" data-aos="fade-up" data-aos-delay="300">
        <p className="ourteam-thankyou-text">
          These are the people that make the magic happen.
        </p>
      </div>

      <div className="ourteam-content-container">

      <section className="ourteam-content-card" data-aos="fade-up" data-aos-delay="400">
          <div className="ourteam-card-body">
            <div className="ourteam-member-info">
              <h2 className="ourteam-card-title">Ramesh Kumar</h2>
              <p className="ourteam-card-text">Co-Founder</p>
            </div>
          </div>
        </section>

        <section className="ourteam-content-card" data-aos="fade-up" data-aos-delay="400">
          <div className="ourteam-card-body">
            <div className="ourteam-member-info">
              <h2 className="ourteam-card-title">Vinod Kumar</h2>
              <p className="ourteam-card-text">Salesforce Architect</p>
             
            </div>
          </div>
        </section>

        <section className="ourteam-content-card" data-aos="fade-up" data-aos-delay="500">
          <div className="ourteam-card-body">
            <div className="ourteam-member-info">
              <h2 className="ourteam-card-title">Ritika Mehta</h2>
              <p className="ourteam-card-text">Java/Salesforce Engineer</p>
              
            </div>
          </div>
        </section>

        <section className="ourteam-content-card" data-aos="fade-up" data-aos-delay="600">
          <div className="ourteam-card-body">
            <div className="ourteam-member-info">
              <h2 className="ourteam-card-title">Mukesh Kumar</h2>
              <p className="ourteam-card-text">Web Developer</p>
              
            </div>
          </div>
        </section>

        <section className="ourteam-content-card" data-aos="fade-up" data-aos-delay="700">
          <div className="ourteam-card-body">
            <div className="ourteam-member-info">
              <h2 className="ourteam-card-title">Dheeraj Sharma</h2>
              <p className="ourteam-card-text">Sr. Salesforce Engineer</p>
              
            </div>
          </div>
        </section>

        <section className="ourteam-content-card" data-aos="fade-up" data-aos-delay="700">
          <div className="ourteam-card-body">
            <div className="ourteam-member-info">
              <h2 className="ourteam-card-title">Darpan Mehta</h2>
              <p className="ourteam-card-text">Full Stack Web Developer</p>
              
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default OurTeam;
