import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import '../Css/Health_Cloud.css';
import AOS from 'aos';
import IntegrationImg from '../Services pages/images/health1.jpg'; // Path to new image for the section

function HealthCloud() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div>
      {/* Header Section */}
      <header className="health-cloud-header" data-aos="fade-in">
        <div className="health-cloud-overlay">
          <h1>Salesforce Health Cloud Solutions</h1>
          <p>WWApps helps you take patient relationships to new heights with the Salesforce Health Cloud.</p>
        </div>
      </header>
      
      {/* Why Opt For Salesforce Health Cloud Solutions Section */}
      <section className="health-cloud-new-section" data-aos="fade-up">
        <h2>Why Opt For Salesforce Health Cloud Solutions?</h2>
        <p className="health-cloud-intro-text">Gain a 360-degree view of every patient and customer with the world’s #1 patient relationship platform.</p>

        <div className="health-cloud-info-container">
          {/* Information Cards */}
          <div className="health-cloud-info-card">
            <i className="fas fa-user-md"></i>
            <h3>Attract More Patients With 1:1 Journeys</h3>
            <p>Access our Salesforce Health Cloud Integration services to lay the foundation for enduring and engaging patient relationships by offering 1:1 and personalized experiences across every touchpoint. Access the best of Salesforce Health Cloud Implementation Services from acquisition through care coordination and ongoing engagement.</p>
          </div>
          <div className="health-cloud-info-card">
            <i className="fas fa-hospital-user"></i>
            <h3>Win Trust Of Members With Personalized Engagement</h3>
            <p>Our Salesforce Health Cloud experts help you deepen member relationships with connected experiences from enrolment and sales to clinical and administrative support. Find out how WWApps can help you anticipate member needs accurately and precisely to deliver proactive care.</p>
          </div>
          <div className="health-cloud-info-card">
            <i className="fas fa-stethoscope"></i>
            <h3>Deliver Better Care With A Single, 360-Degree View Of The Patient</h3>
            <p>Salesforce Health Cloud Implementation Services offered by WWApps help you enable healthcare teams to collaborate seamlessly and effortlessly from a single point of engagement for all patient data. Maximize healthcare efficiencies while delivering top-notch patient experiences.</p>
          </div>
          <div className="health-cloud-info-card">
            <i className="fas fa-user-check"></i>
            <h3>Transform Member Relationships</h3>
            <p>Gain a complete and comprehensive view of each member and population with an out-of-the-box, scalable digital platform that solves the most pressing challenges in today's times. Hire certified Salesforce Health Cloud Specialists to drive cost-effective and personalized care to achieve the best member outcomes.</p>
          </div>
          <div className="health-cloud-info-card">
            <i className="fas fa-hands-helping"></i>
            <h3>Personalized Care With Health Cloud</h3>
            <p>Explore innovative and creative ways of how a 360-degree view of your patients can help you identify their exact requirements and how your healthcare institution can put them at the center of care. Choose the industry leader for Salesforce Health Cloud Implementation Services.</p>
          </div>
          <div className="health-cloud-info-card">
            <i className="fas fa-rocket"></i>
            <h3>Enable Faster Innovation And Drive Better Outcomes</h3>
            <p>Streamline your healthcare institutions by connecting data, processes, and people - from research and development through patient support and commercial operations. Find out how WWApps can help you drive better outcomes and enable faster innovation to transform your organization.</p>
          </div>
          <div className="health-cloud-info-card">
            <i className="fas fa-network-wired"></i>
            <h3>Develop Patient-Centric Services And Innovations Faster</h3>
            <p>Find out how the Salesforce Health Cloud can offer a secure, single platform for you to share critical data between R&D teams, field researchers, patients, partners, and more, at every single point of the customer lifecycle. Hire top Health Cloud Implementation specialists now!</p>
          </div>
        </div>
      </section>

      {/* New Section with Image and Text */}
      <section className="health-cloud-integration-section" data-aos="fade-up">
        <div className="health-cloud-integration-container">
          <div className="health-cloud-integration-text">
            <h2>Why Choose WWApps For Salesforce Health Cloud Integration?</h2>
            <p>Our Salesforce Health Cloud Integration services will empower your caregiver collaboration and in-home care teams on a mobile-first platform, which is powered by the integration of Field Service Lightning with Health Cloud.</p>
            <p>We help you integrate Salesforce Marketing Cloud with Salesforce Health Cloud so that caregivers are empowered to personalize patient content for improving digital engagement and interactions.</p>
            <p>Our Salesforce Health Cloud specialists help you connect the care journey and deliver personalized experiences beyond the expectations of the patients. We help you eliminate the challenge of surfacing the right data to the right provider at the right time.</p>
            <p>WWApps helps you provide easier access to care to patients on their terms by exploring new and innovative ways to book appointments online.</p>
          </div>
          <div className="health-cloud-integration-image">
            <img src={IntegrationImg} alt="Health Cloud Integration" />
          </div>
        </div>
      </section>
      
      {/* Health Cloud Services Section */}
      <section className="health-cloud-services-section" data-aos="fade-up">
        <h2>Our Salesforce Health Cloud Services</h2>
        <div className="health-cloud-services-container">
          <div className="health-cloud-service-card">
            <i className="fas fa-cogs"></i> {/* Example FontAwesome icon */}
            <h3>Health Cloud Implementation</h3>
          </div>
          <div className="health-cloud-service-card">
            <i className="fas fa-plug"></i> {/* Example FontAwesome icon */}
            <h3>Health Cloud Integration</h3>
          </div>
          <div className="health-cloud-service-card">
            <i className="fas fa-comments"></i> {/* Example FontAwesome icon */}
            <h3>Health Cloud Consulting</h3>
          </div>
          <div className="health-cloud-service-card">
            <i className="fas fa-tachometer-alt"></i> {/* Example FontAwesome icon */}
            <h3>Health Cloud Deployment</h3>
          </div>
          <div className="health-cloud-service-card">
            <i className="fas fa-life-ring"></i> {/* Example FontAwesome icon */}
            <h3>Health Cloud Support</h3>
          </div>
          <div className="health-cloud-service-card">
            <i className="fas fa-database"></i> {/* Example FontAwesome icon */}
            <h3>Health Cloud Data Migration</h3>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HealthCloud;
