import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from '../Inc/Navbar'
import Home from './Home';
import About from './Company pages/About';
import Services from './Services';
import Contact from './Contact';
import Footer from '../Inc/Footer';
import OurTeam from './Company pages/Our_Team';
import SalesCloud from './Services pages/Sales_Cloud';
import ServiceCloud from './Services pages/Service_Cloud';
import MarketingCloud from './Services pages/Marketing_Cloud';
import CommerceCloud from './Services pages/Commerce_Cloud';
import HealthCloud from './Services pages/Health_Cloud';
import ManufacturingCloud from './Services pages/Manufacturing_Cloud';
import CommunityCloud from './Services pages/Community_Cloud'
import CustomerSuccess from './Company pages/Customer_Success';
import FieldService from './Services pages/Field_Service';
import SalesDx from './Services pages/Sales_DX'
import DigitalMarketing from './Services pages/Digital_Marketing';
import AdminDeveloper from './Services pages/Admin_Developer';
import AppDevelopment from './Services pages/App_Development';
import ConsultingServices from './Services pages/Consulting_Services';
import CommunitySites from './Services pages/Community_Sites';
import Angular from './Services pages/Angular';
import Node from './Services pages/Node';
import React from './Services pages/React';

function AppRoutes() 
{
    return (
        <div>
            <Router>
                
                <Navbar />
                <Routes>
                <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/salesCloud" element={<SalesCloud />} />
                    <Route path="/serviceCloud" element={<ServiceCloud />} />
                    <Route path="/marketingCloud" element={<MarketingCloud />} />
                    <Route path="/commerceCloud" element={<CommerceCloud />} />
                    <Route path="/healthCloud" element={<HealthCloud />} />
                    <Route path="/manufacturingCloud" element={<ManufacturingCloud />} />
                    <Route path="/communityCloud" element={<CommunityCloud />} />
                    <Route path="/ourTeam" element={<OurTeam />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/customerSuccess" element={<CustomerSuccess />} />
                    <Route path="/fieldService" element={<FieldService />} />
                    <Route path="/DX" element={<SalesDx />} />
                    <Route path="/digitalMarketing" element={<DigitalMarketing />} />
                    <Route path="/adminDeveloper" element={<AdminDeveloper />} />
                    <Route path="/appDevelopment" element={<AppDevelopment />} />
                    <Route path="/consultingServices" element={<ConsultingServices />} />
                    <Route path="/communitySites" element={<CommunitySites />} />
                    <Route path="/angular" element={<Angular />} />
                    <Route path="/node" element={<Node />} />
                    <Route path="/react" element={<React />} />
                    <Route path="*" element={<Home />} />
                    
                    
                </Routes>
                <Footer />
            </Router> 

        </div>
    )

}

export default AppRoutes;