import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import '../Css/Consulting_Services.css'; // Ensure CSS path is correct
import HeaderImg from '../Services pages/images/consulting.jpg';
import RightImage from '../Services pages/images/consulting1.jpg'; // Update with your image path
import AOS from 'aos';
import SalesCloudImg from '../Services pages/images/consulting2.jpg';
import ServiceCloudImg from '../Services pages/images/consulting3.jpg';
import CommunityCloudImg from '../Services pages/images/consulting4.jpg';
import MarketingCloudImg from '../Services pages/images/consulting5.jpg';
import CommerceCloudImg from '../Services pages/images/consulting6.jpg';
import IoTCloudImg from '../Services pages/images/consulting7.jpg';

function ConsultingServices() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div>
      {/* Header Section */}
      <div className="consulting-services-header-section" data-aos="fade-up">
        <img
          src={HeaderImg}
          alt="Salesforce Consulting Services"
          className="img-fluid consulting-services-header-image"
        />
        <div className="consulting-services-overlay">
          <div className="consulting-services-overlay-text">
            <h1>Salesforce Consulting Services</h1>
            <p>
              Boost productivity and improve customer service; transform your business by making the most
              of your data and resources by expert Salesforce Consulting Services from Cloud Analogy.
            </p>
          </div>
        </div>
      </div>

      {/* New Section: Text Left, Image Right */}
      <div className="consulting-services-section">
        <div className="container">
          <div className="row align-items-center">
            {/* Text Column */}
            <div className="col-md-6 consulting-services-text" data-aos="fade-right">
              <h2>Delivering Success by Redefining Customer Relationships</h2>
              <p>
                At Cloud Analogy, our goal is to make your business journey into the future exciting and successful.
                We help you redefine your customer relationships in the wake of the dynamic technological landscape.
                With our breadth of Salesforce solutions, we help organizations transform the way they connect, 
                interact, and engage with their customers.
              </p>
              <p>
                Our hand-customized Salesforce solutions will help you boost sales and increase productivity. 
                Our Salesforce Implementation Services help your organization stay ahead of the competitors by 
                taking your business to the next level. Hire Cloud Analogy – the world’s favorite Salesforce 
                Implementation Expert now!
              </p>
            </div>

            {/* Image Column */}
            <div className="col-md-6 consulting-services-image" data-aos="fade-left">
              <img
                src={RightImage}
                alt="Salesforce Implementation Services"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      {/* New Section: Delivering Success Efficiently */}
      <div className="consulting-services-efficiency-section" data-aos="fade-up">
        <div className="container">
          <h2>Delivering Success Efficiently</h2>
          <p>
            We have helped our global clients with value-driven, cost-effective, and end-to-end Consulting Services for Salesforce platform. We are the trusted Salesforce Partner helping our clients accelerate CRM transformation by maximizing ROI. Our dynamic team of certified and experienced Salesforce Consultants can help you to achieve functional efficiency with Salesforce. Hire experienced Salesforce Implementation Expert now!
          </p>
          <p>
            Our expertise in Salesforce Consulting along with our experience across domains and industries has helped us to master end-to-end Salesforce solutions delivery. Hire certified Salesforce Implementation Partner now!
          </p>
        </div>
      </div>

      {/* Cards Section */}
      <div className="row">
        {/* Card 1: Sales Cloud */}
        <div className="col-md-4 mb-4" data-aos="flip-left">
          <div className="card">
            <img
              src={SalesCloudImg}
              alt="Boost Sales with Sales Cloud"
              className="card-img-top"
            />
            <div className="card-body">
              <h5 className="card-title">Boost Sales with Sales Cloud</h5>
              <p className="card-text">
                Have all your customer information in one place; get better customer insights and give intelligent alerts on the best leads. Sales Cloud provides you with real-time forecasts throughout the organization while helping you with data to drive good decisions.
              </p>
            </div>
          </div>
        </div>

        {/* Card 2: Service Cloud */}
        <div className="col-md-4 mb-4" data-aos="flip-right">
          <div className="card">
            <img
              src={ServiceCloudImg}
              alt="Customer Service with Service Cloud"
              className="card-img-top"
            />
            <div className="card-body">
              <h5 className="card-title">Customer Service with Service Cloud</h5>
              <p className="card-text">
                Enable easy interaction between your business and customers. Your sales team can work from anywhere with the Service Cloud. Connect with your customers one-to-one via Live Agents and enhance customer experience and maximize agent productivity.
              </p>
            </div>
          </div>
        </div>

        {/* Card 3: Community Cloud */}
        <div className="col-md-4 mb-4" data-aos="flip-left">
          <div className="card">
            <img
              src={CommunityCloudImg}
              alt="Connect with Community Cloud"
              className="card-img-top"
            />
            <div className="card-body">
              <h5 className="card-title">Connect with Community Cloud</h5>
              <p className="card-text">
                Build branded portals for your business to connect with your customers, partners, and employees. Improve engagement and provide remarkable experiences by creating multiple communities for different business purposes and sharing content, information, and experiences.
              </p>
            </div>
          </div>
        </div>

        {/* Card 4: Marketing Cloud */}
        <div className="col-md-4 mb-4" data-aos="flip-right">
          <div className="card">
            <img
              src={MarketingCloudImg}
              alt="Personalize with Marketing Cloud"
              className="card-img-top"
            />
            <div className="card-body">
              <h5 className="card-title">Personalize with Marketing Cloud</h5>
              <p className="card-text">
                Deliver intelligent marketing with personalized engagement activities with your customers across various channels using an integrated customer engagement platform. Offer connected customer engagement across the entire business with integration across the Customer Success Platform.
              </p>
            </div>
          </div>
        </div>

        {/* Card 5: Commerce Cloud */}
        <div className="col-md-4 mb-4" data-aos="flip-left">
          <div className="card">
            <img
              src={CommerceCloudImg}
              alt="Seamless Experience with Commerce Cloud"
              className="card-img-top"
            />
            <div className="card-body">
              <h5 className="card-title">Seamless Experience with Commerce Cloud</h5>
              <p className="card-text">
                Provide the best online shopping experience for your customers. Regardless of whether your business is B2C or B2B, we help your business with your e-commerce strategy. We are experienced in building online experiences and facilitating transactions by developing multiple e-commerce sites for web and mobile.
              </p>
            </div>
          </div>
        </div>

        {/* Card 6: IoT Cloud */}
        <div className="col-md-4 mb-4" data-aos="flip-right">
          <div className="card">
            <img
              src={IoTCloudImg}
              alt="Right Interactions with IoT Cloud"
              className="card-img-top"
            />
            <div className="card-body">
              <h5 className="card-title">Right Interactions with IoT Cloud</h5>
              <p className="card-text">
                Empower your business with a more comprehensive integrated perspective about your customers. The IoT Cloud will help you to initiate actions and real-time responses using massive volumes of data generated from various business sources such as devices, websites, applications, customers, and partners.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultingServices;
