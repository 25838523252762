import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../Css/Field_Service.css';
import fieldImg from '../Services pages/images/field1.jpg';
import implementationImg from '../Services pages/images/field5.jpg'; 
import brandingImg from '../Services pages/images/field6.jpg';
import integrationImg from '../Services pages/images/field7.jpg';

function FieldService() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div className="field-service-container">
      {/* Header Section */}
      <header className="field-service-header">
        <div className="field-service-header-content">
          <h1>Salesforce Field Service</h1>
          <p>Leverage Your Service Cloud Data</p>
          <p>Salesforce Field Service Lightning utilizes Service Cloud Data and Artificial Intelligence from Salesforce Einstein. Enhance productivity and increase the effectiveness of your field service personnel. Provide a seamless experience all throughout for your clients.</p>
        </div>
      </header>

      {/* Combined Content Section */}
      <section className="field-service-combined">
        <div className="field-service-text-wrapper" data-aos="fade-up">
          <h2>Drive Service Enhancements with Real-time Insights</h2>
          <p>Expand the Service Cloud offering from Salesforce and work with Service Cloud by adding functionalities – for monitoring, dispatching, and reporting on the activities of your field service personnel. Leverage various tools to manage scheduling, work orders, and mobile workforce. Provide the service personnel with valuable real-time insights and intelligence to render high-quality services and the required knowledge to cross-sell new services and products – to quickly carve out new opportunities for your business.</p>
          
          <h2>What to Expect from Salesforce Field Service?</h2>
          <p>Meet or exceed customer expectation with Field Service product from Salesforce, by putting the mobile employees in your organization – in the right place and the right time. Work seamlessly with Salesforce1 mobile application and enable the salespeople to complete work orders, while working in their job sites.</p>
          
          <h2>What are the benefits of Salesforce Field Service?</h2>
          <p>Field Service Lightning is a tool that works on the Customer Service platform of Salesforce for mobile intelligent customer service. Close the gap between the customer and company with a connected experience – same as that on the field. Track everything with geocoding for transactions in all areas. Use the mobile app in the offline mode, even in zones with no signals and help in task completion.</p>
        </div>
      </section>

      {/* Implementation Services Section */}
      <section className="field-service-section field-service-implementation">
        <div className="field-service-section-image" data-aos="fade-right">
          <img src={implementationImg} alt="Implementation Services" />
        </div>
        <div className="field-service-section-text" data-aos="fade-left">
          <h2>Implementation Services</h2>
          <p>Salesforce implementation services can range from simple one-off integration to full-scale, long-term engagements. Working with experienced Salesforce consultants can make the entire process seamless. The implementation service is aimed at establishing your Salesforce system’s operational setup for optimized performance and success.</p>
        </div>
      </section>

      {/* Branding Services Section */}
      <section className="field-service-section field-service-branding">
        <div className="field-service-section-image" data-aos="fade-right">
          <img src={brandingImg} alt="Branding Services" />
        </div>
        <div className="field-service-section-text" data-aos="fade-left">
          <h2>Branding Services</h2>
          <p>Enhance your brand’s digital presence with Salesforce’s extensive branding solutions. Custom branding aligns with your organization’s vision and enhances user experience. With a strategic approach, engage customers more effectively, and strengthen your brand’s position in the market.</p>
        </div>
      </section>

      {/* Integration Services Section */}
      <section className="field-service-section field-service-integration">
        <div className="field-service-section-image" data-aos="fade-right">
          <img src={integrationImg} alt="Integration Services" />
        </div>
        <div className="field-service-section-text" data-aos="fade-left">
          <h2>Integration Services</h2>
          <p>Integrate your Salesforce platform with other systems to streamline processes and enhance data accuracy. From CRM to ERP integrations, Salesforce offers flexible solutions tailored to your organizational needs. Integration services ensure that all systems work in harmony, providing a seamless experience for users and customers alike.</p>
        </div>
      </section>
    </div>
  );
}

export default FieldService;
