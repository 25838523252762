import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import '../Css/Community_Cloud.css';
import AOS from 'aos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faHeadset, faChartLine, faBuilding, faHandshake, faShoppingCart, faCommentDots, faKey, faMobileAlt, faBolt, faUserCircle } from '@fortawesome/free-solid-svg-icons'; // Corrected import
import CommunityImg from '../Services pages/images/community1.png';
import CommunityImg1 from '../Services pages/images/community2.jpg';

function CommunityCloud() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div className="community-cloud-container">
      {/* Header Section */}
      <section className="community-cloud-header">
        <div className="community-cloud-header-content">
          <h1 data-aos="fade-up">Salesforce Community Cloud - Salesforce Communities Development</h1>
          <p data-aos="fade-up" data-aos-delay="200">
            Build Personalized Salesforce Communities to Engage with Customers, Employees, and Partners. Hire certified Salesforce Communities Development Consultants now!
          </p>
          
        </div>
      </section>
      
      {/* Main Section */}
      <section className="community-cloud-main">
        <div className="community-cloud-content-wrapper">
          <h2 data-aos="fade-up">Why Salesforce Community Cloud - Salesforce Communities Development?</h2>
          <p data-aos="fade-up" data-aos-delay="200">Connect with people important for your business with Community Cloud (Salesforce Experience Cloud).</p>
        </div>
        
        <div className="community-cloud-cards-container">
          <div className="community-cloud-card" data-aos="fade-up" data-aos-delay="300">
            <FontAwesomeIcon icon={faHeadset} size="3x" className="community-cloud-card-icon"/>
            <h3>Provide Better Customer Service</h3>
            <p>A community helps customers to find answers for themselves and for other customers as well. Focus on more meaningful complex customer issues with the service agents. Create a rich self-service with the Community Cloud Service.</p>
          </div>
          
          <div className="community-cloud-card" data-aos="fade-up" data-aos-delay="400">
            <FontAwesomeIcon icon={faUsers} size="3x" className="community-cloud-card-icon"/>
            <h3>Engage with Customers</h3>
            <p>Support customers by accessing data and make purchase decisions. Community Cloud gives a voice to customers. Provide a one-stop-shop for the customers to have more information on your organizational products and services.</p>
          </div>
          
          <div className="community-cloud-card" data-aos="fade-up" data-aos-delay="500">
            <FontAwesomeIcon icon={faChartLine} size="3x" className="community-cloud-card-icon"/>
            <h3>Improve Employee Productivity</h3>
            <p>Harness the power of Community Cloud to create a social, mobile, and branded Community for your employees with improved productivity. Provide a personalized experience for each employee and offer a real impact on the employees, with increased satisfaction and retention rates.</p>
          </div>
        </div>
        
        <div className="community-cloud-second-row">
          <div className="community-cloud-card" data-aos="fade-up" data-aos-delay="600">
            <FontAwesomeIcon icon={faBuilding} size="3x" className="community-cloud-card-icon"/>
            <h3>Build Communities to Fit into Your Business Ecosystem</h3>
            <p>Build a community that is crucial for your business ecosystem. The ecosystem consists of suppliers, vendors, agencies, job applicants as well as retail locations. Collaborate among the employees, partners, and above all your customers.</p>
          </div>
          
          <div className="community-cloud-card" data-aos="fade-up" data-aos-delay="700">
            <FontAwesomeIcon icon={faHandshake} size="3x" className="community-cloud-card-icon"/>
            <h3>Bring Partners into Business Processes in New Ways</h3>
            <p>Increase sales by connecting with resellers, distributors, and partners in a partner community. Access more information by partners from experts. Update records, generate new leads, and manage funds. Enable to quickly close a higher number of deals.</p>
          </div>
        </div>
      </section>

      {/* New Section1 */}
      <section className="community-cloud-new-section">
        <div className="community-cloud-new-content">
          <div className="community-cloud-new-text">
            <h2>Why Choose Community Cloud Service from WWApps?</h2>
            <p>Generate ideas for new products for your organization, create peer-to-peer communities for the customers to help each other and add eCommerce functionalities to Communities. Hire top Salesforce Community Development experts and Salesforce Experience Cloud experts now!</p>
          </div>
          <div className="community-cloud-new-image">
            <img src={CommunityImg} alt="Community Cloud" />
          </div>
        </div>
        
        <div className="community-cloud-new-cards-container">
          <div className="community-cloud-new-card">
            <FontAwesomeIcon icon={faShoppingCart} size="3x" className="community-cloud-new-card-icon"/>
            <h3>Add Buy Buttons to eCommerce Options</h3>
            <p>Add eCommerce functionalities to your Salesforce Community Cloud such as Add to Cart button or Buy button, directly to the Community pages. Convert the forums of engagement into places where actions take place - by bringing eCommerce to online communities.</p>
          </div>
          
          <div className="community-cloud-new-card">
            <FontAwesomeIcon icon={faUsers} size="3x" className="community-cloud-new-card-icon"/>
            <h3>Management of Partners</h3>
            <p>Collaborate on opportunities and deals to increase revenue for your organization. Onboard the partners quickly by sharing training materials and content. Hire certified Salesforce Communities Development now!</p>
          </div>
          
          <div className="community-cloud-new-card">
            <FontAwesomeIcon icon={faCommentDots} size="3x" className="community-cloud-new-card-icon"/>
            <h3>Create Social Stickiness with Communities</h3>
            <p>Cultivate your own online Communities to ensure stickiness for the visitors to spend more time on the company sites. Build better customer service with Communities and offer a powerful means for crowdsourcing ideas for resolution of complex problems. Create and organize communities better.</p>
          </div>
          
          <div className="community-cloud-new-card">
            <FontAwesomeIcon icon={faKey} size="3x" className="community-cloud-new-card-icon"/>
            <h3>Empower Customers</h3>
            <p>Empower the customers to find solutions for their complex customer service issues by themselves and establish access to knowledge base and peer-to-peer support. Choose the best Salesforce Communities Development company now.</p>
          </div>
        </div>
      </section>

      {/* Service Section */}
      <section className="community-cloud-services">
        <div className="community-cloud-services-content">
          <div className="community-cloud-services-text">
            <h2>Our Salesforce Community Cloud Services</h2>
            <p>Build sophisticated custom communities, enhance your brand and collaborate, anywhere and anytime with top-notch Salesforce Communities Development services.</p>
          </div>
          <div className="community-cloud-services-image">
            <img src={CommunityImg1} alt="Community Cloud Services" />
          </div>
        </div>
        
        <div className="community-cloud-services-cards-container">
          <div className="community-cloud-services-card">
            <FontAwesomeIcon icon={faMobileAlt} size="3x" className="community-cloud-services-card-icon"/>
            <h3>Collaborate Anywhere, Anytime</h3>
            <p>Access the Communities with highly customized mobile apps or mobile browsers with the help of our Salesforce experts. Collaborate successfully from anywhere, anytime and from any devices. Hire Experience Cloud developers now.</p>
          </div>
          
          <div className="community-cloud-services-card">
            <FontAwesomeIcon icon={faBolt} size="3x" className="community-cloud-services-card-icon"/>
            <h3>Build and Deploy Faster with Lightning Bolt</h3>
            <p>Create mobile optimized communities and portals by using Lightning Bolt. This is integrated in a lightning fast way into the Salesforce platform to offer an improved experience to the customers. Get the Lightning Bolt solution now.</p>
          </div>
          
          <div className="community-cloud-services-card">
            <FontAwesomeIcon icon={faUserCircle} size="3x" className="community-cloud-services-card-icon"/>
            <h3>Personalized Communities</h3>
            <p>Create personalized experiences for your customers with tailored content and features. Use Community Cloud to offer unique and personalized community experiences to your users. Enhance user satisfaction and engagement with custom solutions.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CommunityCloud;
