import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css'; // Import AOS CSS
import '../Css/React.css'; // Ensure this CSS file has updated styles
import HeaderImg from '../Services pages/images/react.jpg'; // Update with your image path
import AnotherImg from '../Services pages/images/react1.jpg'; // Update with your image path

import AOS from 'aos';

function ReactJs() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animation
      easing: 'ease-in-out', // Easing function
      once: true, // Animate only once
    });
  }, []);

  return (
    <div>
      {/* Header section with image and text overlay */}
      <section className="react-header-container">
        <img src={HeaderImg} alt="ReactJS Development" className="img-fluid w-100" />
        <div className="react-overlay-text">
          <h1 data-aos="fade-up">ReactJS Development Services</h1>
          <p data-aos="fade-up" data-aos-delay="200">
            Power your web and mobile applications with speed, simplicity, and scalability – by building interactive User Interfaces developed using ReactJS Development Services from WWApps.
          </p>
        </div>
      </section>

      {/* New section with additional content */}
      <section className="react-info-section">
        <div className="container">
          <h2 data-aos="fade-up">ReactJS Development</h2>
          <h3 data-aos="fade-up" data-aos-delay="200">Render Flexibility to Your Design Component with the Best Use of ReactJS Features</h3>
          <p data-aos="fade-up" data-aos-delay="400">
            Businesses require a website capable of handling large data while frequently changing without loading the page again. The ReactJS developmental framework is cut-out to do this job perfectly. As an open-source JavaScript framework, ReactJS gives the flexibility to create visually rich front-end with minimal effort and coding. You can create simple, scalable and adaptive web apps with interactive user interfaces in addition to improving the speed of the apps. Our team of ReactJS developers’ expertise in powering your large applications using ReactJS platform. We have proven skills and extensive experience in creating impressive web apps and help you develop user-friendly applications.
          </p>
        </div>
      </section>

      {/* New section with image on the left */}
      <section className="react-fast-implementation">
        <div className="container d-flex align-items-center">
          <div className="react-fast-img">
            <img src={AnotherImg} alt="Fast Implementation" className="img-fluid" />
          </div>
          <div className="react-fast-text">
            <h2 data-aos="fade-up">Fast Implementation</h2>
            <p data-aos="fade-up" data-aos-delay="200">
              The fact that ReactJS is not a full-fledged framework but just a view layer makes it amazingly easy for developers to work with – leads to rapid implementation into any projects. The ease with which modifications can be made saves a lot of time for the developers and in turn your business too.
            </p>
          </div>
        </div>
      </section>

      {/* New section with three columns */}
      <section className="react-features">
        <div className="container">
          <div className="row">
            <div className="col-md-4 react-feature-item" data-aos="fade-up">
              <h3>Easy Dynamic Web Apps</h3>
              <p>A significant amount of complex coding is required in creating dynamic web apps. ReactJS uses JSX which is an HTML-like syntax to build machine-readable code.</p>
            </div>
            <div className="col-md-4 react-feature-item" data-aos="fade-up" data-aos-delay="200">
              <h3>Developing Library</h3>
              <p>ReactJS has an open-source rich JavaScript Library with free access to a multitude of applications and tools. It offers great flexibility to app developers.</p>
            </div>
            <div className="col-md-4 react-feature-item" data-aos="fade-up" data-aos-delay="400">
              <h3>Automatic Modification</h3>
              <p>ReactJS, owing to its design, facilitates data changes by automatic modification of the selected parts of the user interface – resulting in speed and scalability.</p>
            </div>
          </div>
        </div>
      </section>

      {/* New section with three columns again */}
      <section className="react-advantages">
  <div className="container">
    <div className="row">
      <div className="col-md-4 react-advantage-item bg-color-one" data-aos="fade-up">
        <div className="icon">
          <i className="fas fa-bolt fa-3x"></i> {/* Fast Implementation icon */}
        </div>
        <h3>Swift Implementation</h3>
        <p>Owing to the fact that ReactJS is not a full-fledged framework, and is just a view layer, it is preferred most by the developers as it leads to fast implementation in projects.</p>
      </div>
      <div className="col-md-4 react-advantage-item bg-color-two" data-aos="fade-up" data-aos-delay="200">
        <div className="icon">
          <i className="fas fa-code fa-3x"></i> {/* Reusability of Code icon */}
        </div>
        <h3>Reusability of Code</h3>
        <p>One of the critical advantages of ReactJS is its reusability of the code components. It helps to manage system updates efficiently while saving developers time and efforts.</p>
      </div>
      <div className="col-md-4 react-advantage-item bg-color-three" data-aos="fade-up" data-aos-delay="400">
        <div className="icon">
          <i className="fas fa-tools fa-3x"></i> {/* Extensive Developer Tools icon */}
        </div>
        <h3>Extensive Developer Tools</h3>
        <p>The design and debugging tools of ReactJS is comprehensive and available for Chrome and Firefox. Developers can select the individual component to edit its properties and state.</p>
      </div>
    </div>
  </div>
</section>

{/* New section with development services */}
<section className="react-development-services">
  <div className="container">
    <h2 data-aos="fade-up">ReactJS Development Services</h2>
    <p data-aos="fade-up" data-aos-delay="200">
      As a leading ReactJS Development Company, we have gained niche expertise in ReactJS framework. This includes a broad spectrum of development services to build fast and scalable applications.
    </p>
    <ul className="react-services-list" data-aos="fade-up" data-aos-delay="400">
      <li>Front-end development services</li>
      <li>Cross-platform mobile app development</li>
      <li>UI designing and development</li>
      <li>Web app development</li>
      <li>Custom Plug-in development</li>
      <li>Component development</li>
    </ul>
  </div>
</section>

{/* New section with benefits of ReactJS development */}
<section className="react-benefits">
  <div className="container">
    <h2 data-aos="fade-up">Benefits of ReactJS Development</h2>
    <div className="react-benefits-list">
      <div className="react-benefit-item" data-aos="fade-up">
        <i className="fas fa-sync-alt"></i>
        <h3>Integrated with Virtual DOM</h3>
        <p>Developers always face problems when DOM is upgraded as it slows down the performance. But, in ReactJS, any changes that happen in the actual DOM gets updated automatically in the virtual DOM resulting in faster performance.</p>
      </div>
      <div className="react-benefit-item" data-aos="fade-up" data-aos-delay="200">
        <i className="fas fa-tools"></i>
        <h3>Extensive Developer Tools</h3>
        <p>The design and debugging tools of ReactJS are comprehensive and available for Chrome and Firefox, making the task of the developers much easier. They can select individual components to edit their state and properties.</p>
      </div>
      <div className="react-benefit-item" data-aos="fade-up" data-aos-delay="400">
        <i className="fas fa-graduation-cap"></i>
        <h3>Quicker Skill Upgradation</h3>
        <p>Though not a full-blown framework, it is easy for developers from a JavaScript background to learn ReactJS. It is simpler to learn in comparison with other frameworks. Also, documents and tutorials come in handy during skill upgradation.</p>
      </div>
      <div className="react-benefit-item" data-aos="fade-up" data-aos-delay="600">
        <i className="fas fa-code-branch"></i>
        <h3>Isomorphic Apps</h3>
        <p>ReactJS is advantageous in developing Isomorphic apps allowing developers to use similar code for server-side and client-side components of the application. Also, ReactJS enables indexing of website pages, enhancing user experience.</p>
      </div>
      <div className="react-benefit-item" data-aos="fade-up" data-aos-delay="800">
        <i className="fas fa-search"></i>
        <h3>SEO-friendly Framework</h3>
        <p>ReactJS, as a JavaScript framework, helps developers build SEO-friendly web apps with engaging user interfaces and improves search rankings by easily navigating various search engines.</p>
      </div>
      <div className="react-benefit-item" data-aos="fade-up" data-aos-delay="1000">
        <i className="fas fa-bug"></i>
        <h3>Testing and Debugging</h3>
        <p>ReactJS is a developer-friendly framework. It offers ample room for ReactJS developers to test and debug their code with the help of native tools.</p>
      </div>
    </div>
  </div>
</section>


    </div>
  );
}

export default ReactJs;
