import React, { useEffect } from "react";
import '../Css/About.css'; // Ensure this CSS file has the appropriate styles
import BackgroundImage from '../Company pages/images/ab.jpg'; // Replace with the path to your image
import TeamImage from '../Company pages/images/about section.jpg'; // Replace with the path to your new image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faShieldAlt, faLightbulb, faUsers, faTrophy, faHandshake } from '@fortawesome/free-solid-svg-icons';
import 'aos/dist/aos.css'; // Import AOS CSS
import AOS from 'aos';

function About() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animation
      easing: 'ease-in-out', // Easing function
      once: true, // Animate only once
    });
  }, []);

  return (
    <div className="about-container">
      {/* Existing Banner Section */}
      <div className="about-banner" data-aos="fade-in" data-aos-duration="1200">
        <img src={BackgroundImage} alt="About Us" className="about-banner-image" />
        <div className="about-banner-text">
          <p>
            WWApps is a Salesforce Development Company, delivering all-inclusive Salesforce implementation, customization, consultation, and other CRM services such as Hubspot, Zoho, and Microsoft Nav Dynamics.
          </p>
        </div>
      </div>
      
      {/* New Section with Image and Text */}
      <section className="about-section">
        <div className="about-image-container" data-aos="fade-right" data-aos-duration="1200">
          <img src={TeamImage} alt="Our Team" className="about-image" />
        </div>
        <div className="about-text-container" data-aos="fade-left" data-aos-duration="1200">
          <h2>Who We Are?</h2>
          <p>
            WWApps is the world’s leading Salesforce Development Company that offers cutting-edge cloud computing solutions and services to keep our clients' businesses ahead of the crowd by miles.
          </p>
          <p>
            Our teams of experienced Salesforce professionals are problem-solvers and collaborate with our clients and customers to solve the most complicated challenges. WWApps continues to innovate for the future by leveraging new technologies. Our certified experts evaluate plans, designs, and deploy the best services and solutions to cater to the specific requirements of your business. When it’s about Salesforce, it is only about us.
          </p>
        </div>
      </section>
      
      {/* New Values Section */}
      <section className="values-section">
        <h2 data-aos="fade-up" data-aos-duration="1200">Our Values</h2>
        <div className="values-cards">
          <div className="value-card" data-aos="flip-up" data-aos-duration="1200">
            <FontAwesomeIcon icon={faStar} className="card-icon" />
            <h3>Genuine</h3>
            <p>WWApps strives for excellence in all that we do. We set high personal and company standards, and then consistently try to exceed them. At WWApps, we truly believe that transparent and honest communication builds trust that is critical to every successful long-term relationship, whether it is with our employees or customers.</p>
          </div>
          <div className="value-card" data-aos="flip-up" data-aos-duration="1200">
            <FontAwesomeIcon icon={faShieldAlt} className="card-icon" />
            <h3>Integrity</h3>
            <p>WWApps upholds its reputation for integrity in the marketplace and provides an ethical work environment for all our employees. We do what is right, not what is the simplest way out. After all, it's about being open, honest, and respectful at all times with our colleagues, partners, vendors, and customers.</p>
          </div>
          <div className="value-card" data-aos="flip-up" data-aos-duration="1200">
            <FontAwesomeIcon icon={faLightbulb} className="card-icon" />
            <h3>Innovation</h3>
            <p>We promote a creative work environment where we continue to challenge ourselves to improve our processes and methodologies. WWApps takes pride in our work ensuring that the services and products we provide are exceptional and fully reliable. We believe that flexibility leads to excellence and tailor all our services to the specific needs of each client.</p>
          </div>
          <div className="value-card" data-aos="flip-up" data-aos-duration="1200">
            <FontAwesomeIcon icon={faUsers} className="card-icon" />
            <h3>Client Focus</h3>
            <p>WWApps strives to deliver top-rated Salesforce products, services, and solutions for enabling a smarter, connected, and sustainable world. We uphold our reputation for pursuing diversity and integrity in our markets, our services, and our workforce through unique solutions and innovative processes. We consistently accomplish our goals ahead of our own expectations and provide great value to our customers.</p>
          </div>
          <div className="value-card" data-aos="flip-up" data-aos-duration="1200">
            <FontAwesomeIcon icon={faTrophy} className="card-icon" />
            <h3>Excellence</h3>
            <p>WWApps steers relentlessly toward the organizational goals and focuses on the processes and results that matter. Our focus is driven and constant with absolute intention and passion. Our mindset, strategy, and team are aimed at offering maximum benefits to your business customers. We help our customers achieve extraordinary things so that we can grow together further.</p>
          </div>
          <div className="value-card" data-aos="flip-up" data-aos-duration="1200">
            <FontAwesomeIcon icon={faHandshake} className="card-icon" />
            <h3>Team Spirit</h3>
            <p>Our strength lies in the talented employees of our company. We create an environment where everyone is valued and respected, encouraged to contribute and share and is recognized and valued for their efforts. WWApps is continuously moving forward, innovating, and improving.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
