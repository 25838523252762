import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import '../Css/Commerce_Cloud.css';
import AOS from 'aos';
import CommerceImg from '../Services pages/images/commerce1.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faGlobe, faTachometerAlt, faCogs } from '@fortawesome/free-solid-svg-icons';

function CommerceCloud() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div>
      {/* Header Section */}
      <header className="commerce-cloud-header" data-aos="fade-in">
        <div className="commerce-cloud-header-overlay">
          <h1>Salesforce Commerce Cloud Solutions</h1>
          <p>Hire certified Salesforce Commerce Cloud developers now!</p>
        </div>
      </header>

      {/* Highlight Section with Image */}
      <section className="commerce-cloud-highlight-section" data-aos="fade-up">
        <div className="commerce-cloud-image">
          <img src={CommerceImg} alt="Salesforce Commerce Cloud" />
        </div>
        <div className="commerce-cloud-content">
          <h2>Salesforce Commerce Cloud Services</h2>
          <p>
            Hire certified Salesforce Commerce Cloud experts to access the innumerable advantages of the highly scalable, cloud-based software-as-a-service (SaaS) eCommerce solution. Provide highly optimized, engaging, and relevant eCommerce experience to your customers. Our experienced Salesforce Commerce Cloud specialists combine functionalities for online store, eCommerce, mobile-first POS, predictive intelligence, and order management all under a single platform.
          </p>
          <p>
            Empower your online business with seamless, streamlined, and out-of-the-box experience capabilities to manage your pricing, products, promotions, and content to enhance customer engagement. Unify premium customer experiences across all points of customer journeys. Choose <span className="highlight">WWApps</span> – world’s most trusted Salesforce Development Company.
          </p>
        </div>
      </section>

      {/* Detailed Information Section with Cards */}
      <section className="commerce-cloud-info-section" data-aos="fade-up">
        <h2>Explore Our Expertise</h2>
        <div className="commerce-cloud-cards-container">
          <div className="commerce-cloud-info-card" data-aos="zoom-in">
            <div className="commerce-cloud-info-card-inner">
              <div className="commerce-cloud-info-card-front">
                <FontAwesomeIcon icon={faRocket} size="2x" />
                <h3>Salesforce Commerce Cloud</h3>
              </div>
              <div className="commerce-cloud-info-card-back">
                <p>
                  Incorporate predictive capabilities of Einstein to leverage social and multimedia-based data for profile preferences of consumers. Predict the future behavior of prospective clients via Salesforce Commerce Cloud. Recommend to online shoppers based on existing consumer behavior. Hire a certified Salesforce Commerce Cloud developer now!
                </p>
              </div>
            </div>
          </div>

          <div className="commerce-cloud-info-card" data-aos="zoom-in">
            <div className="commerce-cloud-info-card-inner">
              <div className="commerce-cloud-info-card-front">
                <FontAwesomeIcon icon={faGlobe} size="2x" />
                <h3>Salesforce Commerce Cloud for B2C</h3>
              </div>
              <div className="commerce-cloud-info-card-back">
                <p>
                  Create and coordinate the shopper’s experience across devices and channels. Avail an out-of-the-box framework with Storefront Reference Architecture (SFRA). Incorporate the best practices on site design, technical architecture, and merchandising. Enable the retailers to build a unique, mobile-first e-commerce website for brand promotion. Hire an experienced Salesforce Certified Demandware Developer now!
                </p>
              </div>
            </div>
          </div>

          <div className="commerce-cloud-info-card" data-aos="zoom-in">
            <div className="commerce-cloud-info-card-inner">
              <div className="commerce-cloud-info-card-front">
                <FontAwesomeIcon icon={faTachometerAlt} size="2x" />
                <h3>Salesforce Commerce Cloud for B2B</h3>
              </div>
              <div className="commerce-cloud-info-card-back">
                <p>
                  Enable increased efficiency through amazing to create exceptional eCommerce sites with Salesforce B2B Commerce for business buyers. This surrounds an outstanding design for businesses conducting online purchases. Ensure that the B2B customers reach out to the suppliers. Run your business with amazing Salesforce Commerce Cloud solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* New Section */}
      <section className="commerce-cloud-new-section" data-aos="fade-up">
        <h2>Our Salesforce Commerce Cloud Services</h2>
        <p>
          Create unified commerce by encompassing customer service, community and marketing. Empower the entire customer success journey. Enable brands to successfully connect with the shoppers.
          Hire Salesforce Certified Commerce Cloud developers and Salesforce Commerce Cloud Consultants to transform your business.
        </p>
        <div className="commerce-cloud-new-section-details">
          <div className="commerce-cloud-new-section-detail-item" data-aos="fade-right">
            <h3>Visual Design</h3>
            <p>
              Use the Storefront Reference Architecture (SFRA) of Commerce Cloud for the visual design of the storefront. Document out-of-the-box features and functions for B2C commerce with SFRA wireframes which are webpage schematics. Identify the features in HLD and not in the SFRA wireframe to create custom wireframes by the design team.
            </p>
          </div>

          <div className="commerce-cloud-new-section-detail-item" data-aos="fade-left">
            <h3>OmniChannel Commerce & Unified Commerce</h3>
            <p>
              Create a delighting buying experience for clients across all channels – mobile, in-store, and social with OmniChannel Commerce. Render the unified experience for the retailers and the shoppers from marketing, shopping to recommendations of products with technology. Improve your business, increase revenue, and brand value.
            </p>
          </div>

          <div className="commerce-cloud-new-section-detail-item" data-aos="fade-right">
            <h3>Site Optimization and SEO Tools</h3>
            <p>
              Build traffic, improve rank and grow your business faster with the SEO tools in Salesforce Commerce Cloud. Increase organic traffic and boost sales of your business with these SEO tools. Acquire new customers with this way and surely increase the ROI of your business. Hire a certified Salesforce Commerce Cloud developer now!
            </p>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="commerce-cloud-benefits-section" data-aos="fade-up">
        <h2>Benefits Of Salesforce Commerce Cloud</h2>
        <div className="commerce-cloud-benefits-container">
          <div className="commerce-cloud-benefit-item" data-aos="fade-right">
            <FontAwesomeIcon icon={faRocket} size="2x" className="benefit-icon" />
            <h3>Find New Opportunities</h3>
            <p>
              Increase revenue for your business by placing the right products at the right time and right place. Explore new opportunities by obtaining feedback from online shoppers, using social media.
            </p>
          </div>

          <div className="commerce-cloud-benefit-item" data-aos="fade-left">
            <FontAwesomeIcon icon={faTachometerAlt} size="2x" className="benefit-icon" />
            <h3>Achieve Faster Operations</h3>
            <p>
              Achieve faster operation for your business by incorporating Commerce Cloud for quick launching of the websites. Create all new consumer experiences, build new online stores as well as expand your business across different geographies.
            </p>
          </div>

          <div className="commerce-cloud-benefit-item" data-aos="fade-right">
            <FontAwesomeIcon icon={faGlobe} size="2x" className="benefit-icon" />
            <h3>Stay Ahead of Competitors</h3>
            <p>
              Stay ahead of the competitors by always using the latest version of the software. Drive innovation first instead of concentrating on the technology aspect of Salesforce Commerce Cloud. Hire Salesforce Commerce Cloud consultants now.
            </p>
          </div>

          <div className="commerce-cloud-benefit-item" data-aos="fade-left">
            <FontAwesomeIcon icon={faCogs} size="2x" className="benefit-icon" />
            <h3>Manage All Aspects of Digital Commerce</h3>
            <p>
              Manage all aspects of Digital Commerce better - starting right from online shopping and up to delivery of services by using Salesforce Commerce Cloud for driving higher client satisfaction. Transform your business into a higher level.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CommerceCloud;
