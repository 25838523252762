import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../Css/Sales_DX.css'; // Ensure this path is correct
import DxImg from '../Services pages/images/dx1.png'

function SalesDx() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div className="sales-dx-container">
      {/* Header Section */}
      <header className="sales-dx-header" data-aos="fade-up">
        <div className="sales-dx-header-content">
          <h1>Salesforce DX Services</h1>
          <p>
            Build together to deliver continuously with Salesforce DX Services from WWApps. It enables to achieve greater agility with source-driven development, managing development artifacts in the form of code and customizations.
          </p>
        </div>
      </header>

      {/* Developer Productivity Section */}
      <section className="sales-dx-productivity" data-aos="fade-up">
        <div className="sales-dx-content">
          <img 
            src={DxImg} 
            alt="Salesforce DX"
            className="sales-dx-image"
          />
          <div className="sales-dx-text">
            <h2>Boost Developer Productivity</h2>
            <p>
              The source of truth in any traditional development cycle comprises the environment that contains the latest, stable build of the project. This is what is pushed to the next higher environment. Salesforce DX Services from WWApps will help you to change the way this source of truth is defined. The Source of truth is a moving target. It offers a modern developer experience that changes the way Salesforce applications are developed. It offers an integrated end-to-end lifecycle that helps you achieve high-performance agile development. The scratch orgs in Salesforce DX offer source-driven, new types of environments to drive development, testing, and continuous integration.
            </p>
          </div>
        </div>
      </section>
      {/* Services Section */}
      <section className="sales-dx-services" data-aos="fade-up">
        <h2>Our Services Improve Developer Experience</h2>
        <div className="sales-dx-cards">
          <div className="sales-dx-card">
            <h3>Enterprise App Development</h3>
            <p>
              Our adept developers create enterprise apps, in an efficient and modern way, with source-driven, CLI-centric approach, and VS Code for end-to-end solutions.
            </p>
          </div>
          <div className="sales-dx-card">
            <h3>Customized Application Development</h3>
            <p>
              Salesforce DX offers an amazing Development Experience. Our experienced developers have expertise in powerful command line interfaces and open APIs to create out-of-the-box solutions.
            </p>
          </div>
          <div className="sales-dx-card">
            <h3>Testing and Integration</h3>
            <p>
              Our certified experts make use of scratch orgs to test your code and validate changes, and use continuous integration tools to promote code.
            </p>
          </div>
        </div>
      </section>
      {/* Features of Services Section */}
      <section className="sales-dx-features" data-aos="fade-up">
        <h2>Features of Services Rendered by Us</h2>
        <div className="sales-dx-feature-list">
          <div className="sales-dx-feature">
            <h3>Source-driven Development</h3>
            <p>
              Our development team makes use of source-driven development by accessing the latest versions of code and metadata from a centralized source control system.
            </p>
          </div>
          <div className="sales-dx-feature">
            <h3>New way of Managing Releases</h3>
            <p>
              Our certified Salesforce experts have knowledge about unlocked packages in Salesforce DX to offer a novel way to manage releases and changes made to the org.
            </p>
          </div>
          <div className="sales-dx-feature">
            <h3>Embrace Open-source Standards</h3>
            <p>
              We integrate tools and IDEs with Salesforce DX, using open APIs and tools to embrace open-source standards.
            </p>
          </div>
          <div className="sales-dx-feature">
            <h3>Faster Way to Build Applications</h3>
            <p>
              We have the capability to spin off your ideas faster and build applications with the sets of tools that come with Salesforce DX.
            </p>
          </div>
          <div className="sales-dx-feature">
            <h3>Use Scratch Orgs for Automated Testing</h3>
            <p>
              We emulate the Salesforce org with different features and preferences by using the temporary, new type of environment - scratch org for automated testing.
            </p>
          </div>
        </div>
      </section>

      

      {/* Add other sections here */}
    </div>
  );
}

export default SalesDx;
