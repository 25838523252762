import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css'; // Import AOS CSS
import '../Css/Service_Cloud.css'; // Ensure this CSS file has updated styles
import HeaderImg from '../Services pages/images/servicecloud2.jpg'; // Replace with the path to your image
import RightImage from '../Services pages/images/servicecloud3.jpg'; // Replace with the path to your right-side image
import AssetImage from '../Services pages/images/servicecloud4.jpg'
import AOS from 'aos';

function ServiceCloud() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animation
      easing: 'ease-in-out', // Easing function
      once: true, // Animate only once
    });
  }, []);

  return (
    <div>
      {/* Header Section */}
      <div className="servicecloud-header-container">
        <img src={HeaderImg} alt="Salesforce Service Cloud" className="servicecloud-header-image" />
        <div className="servicecloud-header-overlay" data-aos="fade-up">
          <h1>Salesforce Service Cloud</h1>
          <p>
            Hire Salesforce Service Cloud Consultants!<br />
            Choose WWApps, one of the top Salesforce Service Cloud Implementation companies in the USA and globally, for hiring certified Salesforce Service Cloud consultants.
            <br /><br />
            Our Salesforce Service Cloud Consultants help your customer service agents save valuable time, energy, and resources. Improve customer experience by streamlining and sharing customer data across departments. Empower your sales teams with relevant and updated customer information to make quick and better decisions. Choose industry-leading Salesforce Service Cloud Consultants from top Salesforce Implementation Partner now!
          </p>
        </div>
      </div>

      {/* Benefits Section */}
      <section className="servicecloud-benefits">
        <h2>Benefits of Salesforce Service Cloud Implementation</h2>
        <div className="servicecloud-benefits-cards">
          <div className="servicecloud-benefit-card" data-aos="fade-up">
            <h3>Get a Bigger Picture</h3>
            <p>The Service Cloud enables your business to set its goals and metrics that ensure that your business meets these goals. Extend the capability of your customer service department with detailed performance metrics. Hire top Salesforce certified Service Cloud consultants now.</p>
          </div>
          <div className="servicecloud-benefit-card" data-aos="fade-up">
            <h3>Personalized Customer Experience</h3>
            <p>Salesforce certified Service Cloud consultants at WWApps help you deliver a personalized experience for the customer. Choose the world's most trusted and successful Salesforce Service Cloud Implementation organization now, choose WWApps, the world's favorite Salesforce Implementation Partner.</p>
          </div>
          <div className="servicecloud-benefit-card" data-aos="fade-up">
            <h3>Reduce the Workload Of Your Service Personnel</h3>
            <p>Our Salesforce Service Cloud Consultants will equip your Customer Service personnel with the right kind of technology. Choose the best Salesforce Service Cloud Implementation company in the USA that is admired globally. Hire certified Salesforce certified Service Cloud consultant now!</p>
          </div>
        </div>
      </section>

      {/* Why Salesforce Service Cloud Section */}
      <section className="servicecloud-why">
        <div className="servicecloud-why-content">
          <div className="servicecloud-why-cards">
            <div className="servicecloud-why-card" data-aos="fade-up">
              <h3>Offer the Best-in-Class Customer Service</h3>
              <p>Choosing the right platform such as Service Cloud enables you to offer the best customer service as it comprises the right tools. Serve your purpose exactly with a scalable platform and grow with the needs of your increasing customer base. Serve your customers more effectively with an integrated and powerful software such as Service Cloud. Hire certified Salesforce Implementation Experts now!</p>
            </div>
            <div className="servicecloud-why-card" data-aos="fade-up">
              <h3>Work Anywhere, Anytime</h3>
              <p>Manage the customer service teams of your organization anytime, anywhere with the Salesforce Service Cloud empowering and streamlining your business operations. Get access to top Salesforce certified Service Cloud consultants in the industry by choosing WWApps, the world's most trusted Salesforce Service Cloud Implementation company now!</p>
            </div>
          </div>
          <div className="servicecloud-why-image" data-aos="fade-left">
            <img src={RightImage} alt="Salesforce Service Cloud Benefits" />
          </div>
        </div>
      </section>
       {/* Why Choose Our Salesforce Service Cloud Implementation Services Section */}
       <section className="servicecloud-why-choose">
        <div className="servicecloud-why-choose-content">
          <div className="servicecloud-why-choose-image" data-aos="fade-right">
            <img src={AssetImage} alt="Salesforce Service Cloud Benefits" />
          </div>
          <div className="servicecloud-why-choose-text" data-aos="fade-left">
            <h2>Why Choose Our Salesforce Service Cloud Implementation Services?</h2>
            <p><strong>Hire top Salesforce certified Service Cloud consultant now!</strong></p>
            <div className="servicecloud-why-choose-item">
              <h3>Agility of Information</h3>
              <p>The agents have instant access to updated information and this helps in agility of the information for the customers. Hire experienced Salesforce Service Cloud consultants and developers now!</p>
            </div>
            <div className="servicecloud-why-choose-item">
              <h3>Efficient Customer Service</h3>
              <p>WWApps ensures that your customer service is managed with utmost efficiency and professionalism. Choose the best Salesforce certified Service Cloud consultants to transform your business like never before.</p>
            </div>
            <div className="servicecloud-why-choose-item">
              <h3>Easy Set-up of Service Cloud</h3>
              <p>Salesforce certified Service Cloud consultants at WWApps will help your business with Service Cloud Essentials. This ensures that you are able to resolve customer issues without any delay. Choose the best Salesforce certified Service Cloud consultants now!</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServiceCloud;
