import React, { useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import Img from '../Images/carousel-1.jpg';
import Img2 from '../Images/carousel-2.jpg';
import '../Pages/Css/slider.css';  // Make sure this is specific to your Navbar carousel
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

function Slider() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animation
      easing: 'ease-in-out', // Easing function
      once: true, // Animate only once
    });
  }, []);

  return (
    <div>
      {/* Carousel Start */}
      <div className="carousel-container position-relative">
        <Carousel id="header-carousel" className="navbar-carousel slide carousel-fade" data-bs-ride="carousel">
          {/* First Carousel Item */}
          <Carousel.Item data-aos="fade-up" data-aos-delay="100">
            <img className="d-block w-100" src={Img} alt="Image 1" />
            <div className="carousel-overlay"></div>
            <Carousel.Caption className="d-flex flex-column align-items-center justify-content-center animated slideInDown">
              <div className="p-3" style={{ maxWidth: 900 }}>
                <h5 className="text-white text-uppercase mb-3 animated slideInDown" data-aos="fade-up" data-aos-delay="200">
                  Creative &amp; Innovative
                </h5>
                <h1 className="display-1 text-white mb-md-4 animated zoomIn" data-aos="fade-up" data-aos-delay="300">
                  Creative &amp; Innovative Digital Solution
                </h1>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          {/* Second Carousel Item */}
          <Carousel.Item data-aos="fade-up" data-aos-delay="400">
            <img className="d-block w-100" src={Img2} alt="Image 2" />
            <div className="carousel-overlay"></div>
            <Carousel.Caption className="d-flex flex-column align-items-center justify-content-center animated slideInDown">
              <div className="p-3" style={{ maxWidth: 900 }}>
                <h5 className="text-white text-uppercase mb-3 animated slideInDown" data-aos="fade-up" data-aos-delay="500">
                  Stunning Visuals
                </h5>
                <h1 className="display-1 text-white mb-md-4 animated zoomIn" data-aos="fade-up" data-aos-delay="600">
                  Stunning Visuals and Engaging Content
                </h1>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default Slider;
