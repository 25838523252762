import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Typography, Paper, IconButton, Button } from "@mui/material";
import { FaLinkedinIn, FaEnvelope } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpwork } from '@fortawesome/free-brands-svg-icons';
import '../Pages/Css/Contact.css'; 
import supportImage from '../Images/contact-page-title-bg.jpg'; 

function Contact() {
  return (
    <Container className="Contact py-5">
      <Row>
        {/* Left Column: Contact Info */}
        <Col md={6} className="mb-4">
          <Paper className="contact-info p-4 rounded shadow-sm same-height">
            <img 
              src={supportImage} 
              alt="Customer Support" 
              className="img-fluid rounded mb-4" 
            />
          </Paper>
        </Col>

        {/* Right Column: Contact Form */}
        <Col md={6} className="mb-4">
          <Paper className="contact-form p-4 rounded shadow-sm same-height">
            <Typography variant="h4" component="h2" gutterBottom>
              Send Us a Message
            </Typography>

            <form 
              action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D2v0000012b61" 
              method="POST"
              className="salesforce-lead-form"
            >
              {/* Hidden Inputs */}
              <input type="hidden" name="oid" value="00D2v0000012b61" />
              <input id="lead_source" name="lead_source" type="hidden" value="Web" />
              <input type="hidden" name="retURL" value="http://wwapps.netlify.app" />

              {/* First Name Input */}
              <div className="form-group">
                <label htmlFor="first_name">
                  First Name <span className="required">*</span>
                </label>
                <input 
                  id="first_name" 
                  name="first_name" 
                  type="text" 
                  className="form-control" 
                  maxLength="40" 
                  size="20" 
                  required
                />
                <div className="invalid-feedback">
                  Please enter your first name.
                </div>
              </div>

              {/* Last Name Input */}
              <div className="form-group">
                <label htmlFor="last_name">Last Name</label>
                <input 
                  id="last_name" 
                  name="last_name" 
                  type="text" 
                  className="form-control" 
                  maxLength="80" 
                  size="20" 
                />
              </div>

              {/* Email Input */}
              <div className="form-group">
                <label htmlFor="email">
                  Email <span className="required">*</span>
                </label>
                <input 
                  id="email" 
                  name="email" 
                  type="email" 
                  className="form-control" 
                  maxLength="80" 
                  size="20"
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                />
                <div className="invalid-feedback">
                  Please enter a valid email address.
                </div>
              </div>

              {/* Company Input */}
              <div className="form-group">
                <label htmlFor="company">Company</label>
                <input 
                  id="company" 
                  name="company" 
                  type="text" 
                  className="form-control" 
                  maxLength="40" 
                  size="20" 
                />
              </div>

              {/* City Input */}
              <div className="form-group">
                <label htmlFor="city">City</label>
                <input 
                  id="city" 
                  name="city" 
                  type="text" 
                  className="form-control" 
                  maxLength="40" 
                  size="20" 
                />
              </div>

              {/* Mobile Input */}
              <div className="form-group">
                <label htmlFor="mobile">
                  Mobile <span className="required">*</span>
                </label>
                <input  
                  id="mobile" 
                  name="mobile" 
                  type="tel" 
                  className="form-control" 
                  maxLength="40" 
                  size="20"
                  required
                  pattern="\d{10}"  
                  title="Please enter a valid 10-digit phone number."
                />
                <div className="invalid-feedback">
                  Please enter a valid 10-digit phone number.
                </div>
              </div>

              {/* Message Textarea */}
              <div className="form-group">
                <label htmlFor="description">Message</label>
                <textarea 
                  id="description" 
                  name="00NdM000004bM8z" 
                  className="form-control" 
                  rows="4" 
                  wrap="soft"
                />
              </div>

              {/* Submit Button */}
              <Button 
                type="submit" 
                variant="contained" 
                color="primary" 
                fullWidth 
                className="mt-3"
              >
                Send Message
              </Button>
            </form>
          </Paper>
        </Col>
      </Row>

    </Container>
  );
}

export default Contact;
