import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css'; // Import AOS CSS
import '../Css/Customer_Success.css'; // Ensure this CSS file has updated styles
import AOS from 'aos';

// Import images
import testimonialImage1 from '../Services pages/images/customer.jpg';
import testimonialImage2 from '../Services pages/images/customer1.jpg';
import testimonialImage3 from '../Services pages/images/customer2.jpg';
import testimonialImage4 from '../Services pages/images/customer3.jpg';
import testimonialImage5 from '../Services pages/images/customer4.jpg';

function CustomerSuccess() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animation
      easing: 'ease-in-out', // Easing function
      once: true, // Animate only once
    });
  }, []);

  return (
    <div className="customer-success">
      {/* Header Section */}
      <section className="customer-success-header" data-aos="fade-up">
        <div className="header-content">
          <h1>Customer Success Stories</h1>
          <p>Discover how WWApps has positively impacted our clients.</p>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="customer-success-testimonials">
        <div className="testimonials-grid">
          <div className="testimonial" data-aos="fade-up">
            <div className="testimonial-content">
              <div className="testimonial-text">
                <p>
                  WWApps exceeded my high expectations. Their seamless and user-friendly approach to implementing Zoho CRM impressed me. They were transparent, accommodating, and patient throughout the process, making numerous changes without hassle. They explained everything clearly, ensuring we were on the same page. In the end, they delivered the desired solution. Working with WWApps was straightforward and satisfying. I highly recommend their professional and customer-centric services. Thank you, WWApps, for your outstanding support!
                </p>
                <cite>
                Hampstead & Frognal Tutors
                  
                </cite>
              </div>
              <div className="testimonial-image">
                <img src={testimonialImage1} alt="Deepika Ramnath" />
              </div>
            </div>
          </div>

          <div className="testimonial" data-aos="fade-up" data-aos-delay="100">
            <div className="testimonial-content">
              <div className="testimonial-text">
                <p>
                  We are highly impressed with the work delivered by WWApps. Our experience working with their team has been nothing short of amazing. Collaborating with a team with high-level intelligence and a great attitude is refreshing. Whenever we had a request, they promptly fulfilled it, even at odd hours, and always with a smile. The problem-solving capabilities and the ability to find practical solutions were genuinely outstanding. We highly recommend WWApps for its exceptional work ethic and remarkable problem-solving skills.
                </p>
                <cite>
                Strategic Integrators
                  
                </cite>
              </div>
              <div className="testimonial-image">
                <img src={testimonialImage2} alt="Haim Klainman" />
              </div>
            </div>
          </div>

          <div className="testimonial" data-aos="fade-up" data-aos-delay="200">
            <div className="testimonial-content">
              <div className="testimonial-text">
                <p>
                  Working with WWApps has been an exceptional experience. Their expertise in technology platforms and ability to deliver outstanding tasks has been invaluable to our projects. The seamless interactions, progress meetings, and reports ensured smooth collaboration. They consistently met deadlines and displayed excellent leadership. We highly recommend WWApps for its unmatched professionalism and dedication to client satisfaction.
                </p>
                <cite>
                IC Care
                  
                </cite>
              </div>
              <div className="testimonial-image">
                <img src={testimonialImage3} alt="Josh Easton & Ivy Chiu" />
              </div>
            </div>
          </div>

          <div className="testimonial" data-aos="fade-up" data-aos-delay="300">
            <div className="testimonial-content">
              <div className="testimonial-image">
                <img src={testimonialImage4} alt="Miguel Angel Vazuez Carranza" />
              </div>
              <div className="testimonial-text">
                <p>
                  The WWApps has been an invaluable partner in our e-commerce and marketing strategy endeavors. Their work is outstanding, and we've experienced great synergy and smooth communication. We are delighted with the results and look forward to recommending them to others. We are excited to collaborate on our next project!
                </p>
                <cite>
                Lean Layer
                  
                </cite>
              </div>
            </div>
          </div>

          <div className="testimonial" data-aos="fade-up" data-aos-delay="400">
            <div className="testimonial-content">
              <div className="testimonial-image">
                <img src={testimonialImage5} alt="Sreekanth Jakkaraju" />
              </div>
              <div className="testimonial-text">
                <p>
                  WWApps has been instrumental in our organization's success. Their team's exceptional support and expertise have exceeded our expectations. They seamlessly handled urgent requirements and delivered outstanding results. I highly recommend WWApps to other non-profit organizations. Thank you for the exceptional service!
                </p>
                <cite>
                zuberance
                  
                </cite>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CustomerSuccess;
