import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import '../Pages/Css/Navbar.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function NavbarComponent() {
  const [showDropdown, setShowDropdown] = useState('');

  const handleDropdownToggle = (dropdown) => {
    setShowDropdown(showDropdown === dropdown ? '' : dropdown);
  };

  return (
    <Navbar bg="light" variant="light" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand as={NavLink} to="/">WWApps</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <FaBars />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={NavLink} to="/" exact>Home</Nav.Link>

            {/* Company Dropdown */}
            <NavDropdown
              title="Company"
              className="companyDropdown"
              show={showDropdown === 'company'}
              onClick={() => handleDropdownToggle('company')}
              onMouseEnter={() => setShowDropdown('company')}
              onMouseLeave={() => setShowDropdown('')}
            >
              <NavDropdown.Item as={NavLink} to="/ourTeam">
                Our Team
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/customerSuccess">
                Customer Success Story
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/about">
                About Us
              </NavDropdown.Item>
            </NavDropdown>

            {/* Services Dropdown */}
            <NavDropdown
              title="Services"
              className="servicesDropdown"
              show={showDropdown === 'services'}
              onClick={() => handleDropdownToggle('services')}
              onMouseEnter={() => setShowDropdown('services')}
              onMouseLeave={() => setShowDropdown('')}
            >
              <div className="dropdown-sections">
                <div className="dropdown-section">
                  <h5 className="dropdown-header">CRM Services</h5>
                  <NavDropdown.Item as={NavLink} to="/adminDeveloper">
                    Salesforce Admin & Developer Support
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/appDevelopment">
                    Salesforce App Development
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/consultingServices">
                    Salesforce Consulting Services
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="communitySites">
                    Salesforce Communities Sites
                  </NavDropdown.Item>
                </div>
                <div className="dropdown-section">
                  <h5 className="dropdown-header">Salesforce Cloud</h5>
                  <NavDropdown.Item as={NavLink} to="/salesCloud">
                    Sales Cloud
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/serviceCloud">
                    Service Cloud
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/marketingCloud">
                    Marketing Cloud
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/commerceCloud">
                    Commerce Cloud
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/healthCloud">
                    Health Cloud
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/manufacturingCloud">
                    Manufacturing Cloud
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/communityCloud">
                    Community Cloud
                  </NavDropdown.Item>
                </div>
                <div className="dropdown-section">
                  <h5 className="dropdown-header">Web Development</h5>
                  <NavDropdown.Item as={NavLink} to="/fieldService">
                    Salesforce Field Service
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/DX">
                    Salesforce DX
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/digitalMarketing">
                    Digital Marketing Services
                  </NavDropdown.Item>
                </div>
                <div className="dropdown-section">
                  <h5 className="dropdown-header">Full Stack</h5>
                  <NavDropdown.Item as={NavLink} to="/angular">
                    Angular Js
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/node">
                    Node Js
                  </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="/react">
                    React Js
                  </NavDropdown.Item>
                </div>
              </div>
            </NavDropdown>

            <Nav.Link as={NavLink} to="/contact">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
