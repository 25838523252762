import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import '../Css/App_Development.css'; // Make sure to update the path if needed
import AOS from 'aos';
import HeaderImg from '../Services pages/images/Appdevelopment.jpg'; // Update with the correct path to your image
import SectionImage from '../Services pages/images/Appdevelopment1.jpg'; // Update with the correct path to your new image
import CustomizationImg from '../Services pages/images/Appdevelopmenticon.jpg';
import LightningImg from '../Services pages/images/Appdevelopmenticon1.jpg';
import OptimizationImg from '../Services pages/images/Appdevelopmenticon2.jpg';
import ImplementationImg from '../Services pages/images/Appdevelopmenticon3.jpg';
import SupportImg from '../Services pages/images/Appdevelopmenticon4.jpg';
import CRMImg from '../Services pages/images/Appdevelopmenticon5.jpg';
import FasterResponseImg from '../Services pages/images/Appdevelopmenticon6.jpg'; // Add the correct path to the image
import ConnectCustomersImg from '../Services pages/images/Appdevelopmenticon7.jpg'; // Add the correct path to the image
import EaseOfUseImg from '../Services pages/images/Appdevelopmenticon8.jpg'; // Add the correct path to the image
import SecureIntegrationImg from '../Services pages/images/Appdevelopmenticon9.jpg'; // Add the correct path to the image
import SupportServicesImg from '../Services pages/images/Appdevelopmenticon10.jpg'; // Add the correct path to the image
import BusinessProcessesImg from '../Services pages/images/Appdevelopmenticon11.jpg'; // Add the correct path to the image

function AppDevelopment() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div className="app-development-page">
      {/* Header Section */}
      <div className="app-development-header-section" data-aos="fade-in">
        <img src={HeaderImg} alt="Salesforce App Development" className="app-development-header-image" />
        <div className="app-development-overlay-text" data-aos="fade-up">
          <h1>Salesforce App Development</h1>
          <p>Render a Customized Mobile Experience with Salesforce App</p>
          <p>
            Time that we spend on smartphones surpasses web application, since apps account for 86% app usage. 
            Access your company’s CRM data from your mobile devices with Salesforce mobile app. Build highly 
            personalized Salesforce apps and engage customers and connect to Salesforce.
          </p>
        </div>
      </div>

      {/* Salesforce Development Services Section */}
      <div className="app-development-info-section">
        <div className="app-development-text" data-aos="fade-up">
          <h2>Salesforce Development Services</h2>
          <p>
            Salesforce development services from WWApps utilize the power of its CRM to build apps on the Salesforce platform. Empower all with a team of highly experienced and proficient Salesforce developers – to build amazing experiences around the customers. Deliver extensive development services on Salesforce and Force.com to meet the exact requirements of our client. Employ the best practices of app development such as agile development, and enable to create high quality mobile apps, at a low cost.
          </p>
          <h3>Transform Customer Experience with Apps</h3>
          <p>
            Build smart, personalized apps with Salesforce to meet requirements with real-time engagement.
          </p>
          <h3>Build Apps Faster with Lightning</h3>
          <p>
            Create apps faster for your business with Salesforce Lightning platform to empower everyone.
          </p>
        </div>
        <div className="app-development-image" data-aos="fade-left">
          <img src={SectionImage} alt="Salesforce Development Services" />
        </div>
      </div>

      {/* New Salesforce Services Section */}
      <div className="app-development-services-section">
        <h2 data-aos="fade-up">Our Salesforce Development Services</h2>
        <p data-aos="fade-up">
          Deliver end-to-end solutions for our clients with future-ready mobile apps through Salesforce Development services. Ensure that your organization operates in the most effective way.
        </p>
        <div className="service-card-container">
          <div className="service-card" data-aos="fade-up">
            <img src={CustomizationImg} alt="Salesforce Service Customization" className="service-card-image" />
            <div className="service-card-content">
              <h3>Salesforce Service Customization</h3>
              <p>
                We help your business to create customized Salesforce apps to meet all your requirements with our certified Salesforce experts.
              </p>
            </div>
          </div>
          <div className="service-card" data-aos="fade-up">
            <img src={LightningImg} alt="Salesforce Lightning Service" className="service-card-image" />
            <div className="service-card-content">
              <h3>Salesforce Lightning Service</h3>
              <p>
                Extend Salesforce user experiences from mobile to desktop, and Visualforce pages with our experienced professionals to make your Salesforce instance Lightning-ready.
              </p>
            </div>
          </div>
          <div className="service-card" data-aos="fade-up">
            <img src={OptimizationImg} alt="Salesforce Optimization Service" className="service-card-image" />
            <div className="service-card-content">
              <h3>Salesforce Optimization Service</h3>
              <p>
                Ensure that your business fetches the maximum return on investment with Salesforce practices with the Salesforce experts at WWApps - by analyzing your current Salesforce setup.
              </p>
            </div>
          </div>
          <div className="service-card" data-aos="fade-up">
            <img src={ImplementationImg} alt="Salesforce Implementation Service" className="service-card-image" />
            <div className="service-card-content">
              <h3>Salesforce Implementation Service</h3>
              <p>
                Reduce costs and enhance user experience with Salesforce Integration services by easily accessing and integrating information assets.
              </p>
            </div>
          </div>
          <div className="service-card" data-aos="fade-up">
            <img src={SupportImg} alt="Salesforce Support Service" className="service-card-image" />
            <div className="service-card-content">
              <h3>Salesforce Support Service</h3>
              <p>
                Offer flexible, cost-effective services for your organization with Salesforce experts, adding resources without augmenting headcount.
              </p>
            </div>
          </div>
          <div className="service-card" data-aos="fade-up">
            <img src={CRMImg} alt="Salesforce CRM Service" className="service-card-image" />
            <div className="service-card-content">
              <h3>Salesforce CRM Service</h3>
              <p>
                Provide Salesforce CRM services and cater to the needs of businesses of all sizes with Salesforce.com.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Why WWApps Section */}
      <div className="app-development-why-cloud-analogy-section">
        <h2 data-aos="fade-up">Why WWApps - Salesforce App Developer</h2>
        <div className="app-development-why-cloud-analogy-content">
          <p data-aos="fade-up">
            WWApps offers a range of Salesforce services. Create apps as per the unique needs of your organization with the team of efficient developers based on our expertise, on extending Salesforce with Visualforce and Apex.
          </p>
          <div className="app-development-why-cloud-analogy-items">
            <div className="why-cloud-analogy-item" data-aos="fade-up">
              <img src={FasterResponseImg} alt="Faster Response" className="why-cloud-analogy-item-image" />
              <h3>Faster Response</h3>
              <p>We build and launch apps at higher speed, all for faster response to your business requirements.</p>
            </div>
            <div className="why-cloud-analogy-item" data-aos="fade-up">
              <img src={ConnectCustomersImg} alt="Connect with Customers" className="why-cloud-analogy-item-image" />
              <h3>Connect with Customers</h3>
              <p>We leverage the integrated platform of Salesforce to build apps for excellent customer communication.</p>
            </div>
            <div className="why-cloud-analogy-item" data-aos="fade-up">
              <img src={EaseOfUseImg} alt="Ease of Use" className="why-cloud-analogy-item-image" />
              <h3>Ease of Use</h3>
              <p>We make use of the easy-to-use interface of Salesforce for those without a technical background.</p>
            </div>
            <div className="why-cloud-analogy-item" data-aos="fade-up">
              <img src={SecureIntegrationImg} alt="Secure and Seamless Integration" className="why-cloud-analogy-item-image" />
              <h3>Secure and Seamless Integration</h3>
              <p>We offer reliable, secure, scalable, and seamless integration of Salesforce.com with other on-premise applications in your organization.</p>
            </div>
            <div className="why-cloud-analogy-item" data-aos="fade-up">
              <img src={SupportServicesImg} alt="High Quality Support Services" className="why-cloud-analogy-item-image" />
              <h3>High Quality Support Services</h3>
              <p>We offer support services of the highest quality for our clients. We enable you to add resources - without augmenting the overall headcount.</p>
            </div>
            <div className="why-cloud-analogy-item" data-aos="fade-up">
              <img src={BusinessProcessesImg} alt="Effectiveness of Business Processes" className="why-cloud-analogy-item-image" />
              <h3>Effectiveness of Business Processes</h3>
              <p>We increase the efficiency and the effectiveness of the business processes with our customized Salesforce solutions.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppDevelopment;
