import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css'; // Import AOS CSS  
import '../Css/Sales_Cloud.css'; // Ensure this CSS file has updated styles
import HeaderImg from '../Services pages/images/salesCloud.jpg';
import ServiceImg from '../Services pages/images/salesCloud1.jpg'
import AOS from 'aos';

function SalesCloud() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animation
      easing: 'ease-in-out', // Easing function
      once: true, // Animate only once
    });
  }, []);

  return (
    <div className="salescloud-container">
      {/* Header Image Section */}
      <section className="salescloud-header-section">
        <img
          src={HeaderImg}
          alt="Sales Cloud Integration"
          className="salescloud-header-image"
          data-aos="zoom-in"
        />
        <div className="salescloud-header-text-overlay" data-aos="fade-up">
          <h1 className="salescloud-header-title">
            Salesforce Sales Cloud Integration Solutions
          </h1>
          <p className="salescloud-header-subtitle">
            Grow your business and boost your productivity with Salesforce Sales Cloud Integration.
          </p>
        </div>
      </section>

      {/* Main Content Section */}
      <section className="salescloud-main-content">
        <h2 className="salescloud-main-heading">
          Why Opt for Our Salesforce Sales Cloud Integration Solutions?
        </h2>

        <div className="salescloud-content-cards-container">
          <div className="salescloud-content-card" data-aos="fade-up" data-aos-delay="100">
            <div className="salescloud-card-body">
              <i className="fas fa-cogs salescloud-card-icon"></i>
              <h3 className="salescloud-card-title">
                Offer Seamless, Personalized Journey for Every Customer
              </h3>
              <p className="salescloud-card-text">
                We offer seamless and personalized experiences for the buyers by connecting data across sales, marketing, service, and B2B commerce. We can help you track every part of the journey for your customers. Choose the world's best Salesforce Sales Cloud Integration provider to transform your business.
              </p>
            </div>
          </div>

          <div className="salescloud-content-card" data-aos="fade-up" data-aos-delay="200">
            <div className="salescloud-card-body">
              <i className="fas fa-mobile-alt salescloud-card-icon"></i>
              <h3 className="salescloud-card-title">
                Progress with Salesforce App by Accessing Information, Anywhere
              </h3>
              <p className="salescloud-card-text">
                Tap all your meaningful Sales Cloud data with the power of a mobile app. Enable a Salesforce Mobile App and fully take advantage of vital information such as intervals between meetings, etc. Hire certified Salesforce Sales Cloud Integration now to get the best of Salesforce Sales Cloud Integration solutions!
              </p>
            </div>
          </div>

          <div className="salescloud-content-card" data-aos="fade-up" data-aos-delay="300">
            <div className="salescloud-card-body">
              <i className="fas fa-users salescloud-card-icon"></i>
              <h3 className="salescloud-card-title">
                Enhance the Productivity of Your Employees
              </h3>
              <p className="salescloud-card-text">
                The Sales Cloud Services enable your employees to meaningfully devote their time to focusing on sales and not on the painfully mundane administrative tasks. Choose the best Salesforce Sales Cloud Implementation services by selecting WWApps - the world's favorite Salesforce Implementation Partner and Salesforce Implementation Expert!
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Services Section */}
      <section className="salescloud-services-section">
        <h2 className="salescloud-services-heading">
          Our Salesforce Sales Cloud Integration Services
        </h2>

        <div className="salescloud-services-cards-container">
          <div className="salescloud-service-card" data-aos="fade-up" data-aos-delay="100">
            <i className="salescloud-service-icon fas fa-cogs"></i>
            <h3 className="salescloud-service-title">Custom Solution Development</h3>
            <p className="salescloud-service-text">
              The expert Sales Cloud Consultants at WWApps offer customized solutions with Sales Cloud that address the unique challenges faced by your business - thereby ensuring to exactly match your requirements. Hire certified Salesforce Implementation Experts now!
            </p>
          </div>

          <div className="salescloud-service-card" data-aos="fade-up" data-aos-delay="200">
            <i className="salescloud-service-icon fas fa-arrow-right"></i>
            <h3 className="salescloud-service-title">Migration to Sales Cloud</h3>
            <p className="salescloud-service-text">
              Our teams of certified Salesforce experts will guide your organization ready to migrate from your existing system to Salesforce Sales Cloud. They will migrate all your leads, contacts, opportunities, and accounts to the Sales Cloud.
            </p>
          </div>

          <div className="salescloud-service-card" data-aos="fade-up" data-aos-delay="300">
            <i className="salescloud-service-icon fas fa-bolt"></i>
            <h3 className="salescloud-service-title">Migration to Salesforce Lightning</h3>
            <p className="salescloud-service-text">
              The certified Salesforce experts at WWApps will ensure to set the pace of Lightning experience. The experts at WWApps will bring their years of experience in managing the Lightning Migration for your organization. Choose experienced Salesforce Implementation Experts now!
            </p>
          </div>

          <div className="salescloud-service-card" data-aos="fade-up" data-aos-delay="400">
            <i className="salescloud-service-icon fas fa-cogs"></i>
            <h3 className="salescloud-service-title">CPQ Integration</h3>
            <p className="salescloud-service-text">
              We can streamline your configure, price, quote process (CPQ) and drive unprecedented growth for your business. It increases the efficiency of your Sales reps and thereby forms an inherent part of building a successful, multi-channel, global selling strategy for your organization.
            </p>
          </div>

          <div className="salescloud-service-card" data-aos="fade-up" data-aos-delay="500">
            <i className="salescloud-service-icon fas fa-plug"></i>
            <h3 className="salescloud-service-title">Integration of Sales Cloud</h3>
            <p className="salescloud-service-text">
              The certified Salesforce Sales Cloud Integration Consultants make sure to integrate Sales Cloud with your existing technology platform. This ensures that these technologies work collaboratively to obtain the desired results for your business. Choose the best Salesforce Implementation Services and Salesforce integration solutions now!
            </p>
          </div>

          <div className="salescloud-service-card" data-aos="fade-up" data-aos-delay="600">
            <i className="salescloud-service-icon fas fa-chart-line"></i>
            <h3 className="salescloud-service-title">Sales Cloud Analytics</h3>
            <p className="salescloud-service-text">
              The Salesforce Sales Cloud Integration experts at WWApps will improve sales predictions in advance with Sales Cloud Analytics by using Einstein. You can target the right leads by the automated tasks performed by the Sales reps. Einstein provides meaningful insights for closing better deals for your organization.
            </p>
          </div>
        </div>
      </section>
      {/* Why Choose Us Section */}
      <section className="salescloud-why-choose-us">
        <div className="salescloud-why-choose-us-content" data-aos="fade-left">
          <h2 className="salescloud-why-choose-us-heading">
            Why Choose WWApps For Salesforce Sales Cloud Integration?
          </h2>
          <ul className="salescloud-why-choose-us-list">
            <li>Offers innovative customer service across devices - anywhere and anytime.</li>
            <li>Collaborates between the Sales and Marketing teams for a 1:1, personalized journey with the customer.</li>
            <li>Increases lead generation, close deals and improve the ROI of your business by informed decisions, accessing the data with Sales Cloud, from laptops, tablets and other mobile devices.</li>
            <li>Minimizes operational costs and increases time-to-market your products by acceleration of CRM transformational initiatives.</li>
            <li>Eliminates manual tasks that help to increase efficiency with actionable insights.</li>
            <li>Manages effectively the end-to-end process for your Sales Team with the essential tools that accompany Sales Cloud.</li>
          </ul>
        </div>
        <img
          src={ServiceImg}
          alt="Why Choose Us"
          className="salescloud-why-choose-us-image"
          data-aos="fade-right"
        />
      </section>
    </div>
  );
}

export default SalesCloud;
